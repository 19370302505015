import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useQueryParam, NumberParam } from 'use-query-params';

import useEncodedUrlPath from 'hooks/useEncodedUrlPath';
import useOnClickOutside from 'hooks/useOnClickOutside';
import CoursePlannerClassRowDayLabel from './CoursePlannerClassRowDayLabel';
import CoursePlannerClassRowOverlay from './CoursePlannerClassRowOverlay';
import CoursePlannerClassRowStudyPath from './CoursePlannerClassRowStudyPath';
import ContextMenu from './ContextMenu';
import Icon, { IconEnum } from 'shared-components/Icon';
import MoveItemButton from './MoveItemButton';
import { InstructorCoursePath } from 'types/instructor.types';
import {
  BCPViewMode,
  CoursePlannerAction,
  MovingTopic,
  SharedBCPProps,
} from '../BetterCoursePlannerController';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import './CoursePlannerClassRow.scss';

const CoursePlannerClassRow = ({
  addingStudyPath,
  children,
  classSession,
  classDate,
  label,
  movingTopic,
  ...sharedBCPProps
}: SharedBCPProps & {
  addingStudyPath: number | null
  classSession: BetterClassSession
  children: Array<React.ReactNode> | React.ReactNode
  classDate: string
  viewMode: BCPViewMode
  label: string | null
  movingTopic: MovingTopic | null
}) => {
  const {
    classSessionId,
    viewMode,
    handleCoursePlannerAction,
    isProcessing,
  } = sharedBCPProps;
  const [csIdQuery, setCsIdQuery] = useQueryParam('csId', NumberParam);
  const returnToLink = useEncodedUrlPath();
  const loSelectorLink = `${InstructorCoursePath.SelectLearningObjectives}?class-session=${classSessionId}&returnTo=${returnToLink}`;
  const movingFromThisClassSession = !!movingTopic && movingTopic.classSessionId === classSessionId;
  const popupRef = useRef(null);
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const [gearMenuOpen, setGearMenuOpen] = useState(false);
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [isEditingStudyPath, setIsEditingStudyPath] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const { classNumber, classType, groupedAssessmentIds } = classSession;
  const hasStudyPath = !!groupedAssessmentIds.summative?.length;
  const showMoveActions = classType !== ClassTypeEnum.Special && !!movingTopic;

  useOnClickOutside(popupRef, () => {
    if (gearMenuOpen) {
      setGearMenuOpen(false);
    }
  });

  useEffect(() => {
    if (!!csIdQuery && classSessionId === csIdQuery) {
      if (!!scrollToRef.current) {
        console.debug(`scroll to ${classSessionId}`);
        scrollToRef.current.scrollIntoView();
        setCsIdQuery(undefined); // remove csId from query after scrolling to it
      }
    }
  }, [classSessionId, csIdQuery, setCsIdQuery]);

  useEffect(() => {
    // if another overlay opens in a different class, other overlays should close
    if (showOverlay && !!addingStudyPath && addingStudyPath !== classSessionId) {
      setShowOverlay(false);
    }
  }, [addingStudyPath, classSessionId, showOverlay]);

  const renderActionButtons = () => {
    // for BCP beta only show Add LOs button, eventually we will want to reinstate the other functions
    const showOnlyAddLosButton = true;
    if (showOnlyAddLosButton) {
      return (
        <div className="bcp__class-row__actions__buttons bcp__class-row__actions__all-actions" data-classtype={classType}>
          <button
            className="bcp__action-button class-action-button class-action-button__open-topic-lo-picker"
            disabled={isProcessing}
            onClick={() => handleCoursePlannerAction(CoursePlannerAction.ShowAddTopicToClass, { classSessionId, classDate })}
            title={`Add Topics to Class ${classNumber}`}
          >
            <Icon size={20} which={IconEnum.CirclePlus} />
            <span className="bcp__action-button__label">
              Add LOs
            </span>
          </button>
        </div>
      );
    }
    if (!showMoreActions && viewMode === BCPViewMode.Compact) {
      return (
        <div className="bcp__class-row__actions__buttons bcp__class-row__actions__show-more-wrap" data-classtype={classType}>
          <button
            className="bcp__action-button class-action-button class-action-button__show-more-actions"
            disabled={isProcessing}
            onClick={() => setShowMoreActions(true)}
            title="Show More Actions"
          >
            <Icon size={24} which={IconEnum.Notepad} />
            <span className="bcp__action-button__label">
              Add...
            </span>
          </button>
        </div>
      );
    }
    return (
      <div className="bcp__class-row__actions__buttons bcp__class-row__actions__all-actions" data-classtype={classType}>
        <button
          className="bcp__action-button class-action-button class-action-button__open-topic-lo-picker"
          disabled={isProcessing}
          onClick={() => handleCoursePlannerAction(CoursePlannerAction.ShowAddTopicToClass, { classSessionId, classDate })}
          title={`Add Topics to Class ${classNumber}`}
        >
          <Icon size={20} which={IconEnum.CirclePlus} />
          <span className="bcp__action-button__label">
            Add LOs
          </span>
        </button>
        <button
          className="bcp__action-button class-action-button class-action-button__open-topic-lo-picker"
          disabled={isProcessing}
          onClick={() => handleCoursePlannerAction(CoursePlannerAction.ShowAddTopicToClass, { classSessionId, classDate })}
          title={`Add Topics to Class ${classNumber}`}
        >
          <Icon size={20} which={IconEnum.CirclePlus} />
          <span className="bcp__action-button__label">
            Topics
          </span>
        </button>
        <Link
          className="bcp__action-button class-action-button class-action-button__add-los"
          data-disabled={isProcessing}
          to={loSelectorLink}
        >
          <Icon size={20} which={IconEnum.CirclePlus} />
          <span className="bcp__action-button__label">
            LOs
          </span>
        </Link>
        {!hasStudyPath && (
          <button
            className="bcp__action-button class-action-button class-action-button__add-study-path"
            disabled={isProcessing}
            onClick={() => setIsEditingStudyPath(!isEditingStudyPath)}
          >
            <Icon size={20} which={IconEnum.CirclePlus} />
            <span className="bcp__action-button__label">
              Study Path
            </span>
          </button>
        )}
      </div>
    );
  };

  return (
    <div
      className="bcp__class-row"
      data-class-number={classNumber}
      data-class-session-id={classSessionId}
      data-bcp-view-mode-class-row={viewMode}
      ref={scrollToRef}
      id={`class-${classNumber}`}
      key={classNumber}
    >
      <div className="bcp__class-row__gutter">
        <ContextMenu
          items={[
            {
              label: !label ? 'Add Label to Class Day' : 'Edit Class Day Label',
              onClick: () => setIsEditingLabel(true),
            },
          ]}
          triggerIcon={<Icon which={IconEnum.Gear} />}
        />
      </div>
      <div className="bcp__class-row__body">
        <div className="bcp__class-row__info-column">
          <div className="bcp__class-row__info-column__class-number">
            <Link to={`${InstructorCoursePath.DailyPlanner}?class-session=${classSessionId}`}>
              {classType === ClassTypeEnum.Special ? 'No class' : `Class ${classNumber}`}
            </Link>
          </div>
          <div className="bcp__class-row__info-column__class-date-display">
            {DateTime.fromISO(classDate).toFormat(DateFormatEnum.WeekdayMonthDateShort)}
          </div>
        </div>
        <div className="bcp__class-row__content" data-overlaid={showOverlay}>
          <CoursePlannerClassRowDayLabel
            {...sharedBCPProps}
            classSession={classSession}
            initialValues={{
              label: !!label ? label : '',
              classType,
            }}
            isEditing={isEditingLabel}
            setIsEditing={setIsEditingLabel}
            show={isEditingLabel || !!label}
          />
          {children}
          <CoursePlannerClassRowStudyPath
            {...sharedBCPProps}
            key={`${isEditingStudyPath}_${viewMode}`}
            classSession={classSession}
            isEditing={isEditingStudyPath}
            setIsEditing={setIsEditingStudyPath}
          />
          {showOverlay && (
            <CoursePlannerClassRowOverlay
              classDate={classDate}
              classNumber={classNumber}
              classSessionId={classSessionId}
              onClose={() => setShowOverlay(false)}
              handleCoursePlannerAction={handleCoursePlannerAction}
            />
          )}
        </div>
        <div className="bcp__class-row__actions" data-classtype={classType}>
          {!showMoveActions ? renderActionButtons() : (
            <MoveItemButton
              addBtnText={`Move to Class ${classNumber}`}
              addBtnTitle={`Move ${movingTopic.topicName} to Class ${classNumber}`}
              cancelBtnText="Cancel Moving Topic"
              className="bcp__class-row__move-topic"
              disabled={isProcessing}
              onAdd={() => handleCoursePlannerAction(CoursePlannerAction.MoveTopicToThisClass, { classSessionId })}
              onCancel={() => handleCoursePlannerAction(CoursePlannerAction.CancelMovingTopic, { classSessionId })}
              showCancel={movingFromThisClassSession}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePlannerClassRow;
