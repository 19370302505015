/**
 * BetterCoursePlannerController
 */
import React, { useReducer, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { formatAssessmentForApi, formatStudyPathPlaceholderBasedOnPreset } from 'utils/assessmentFunctions';
import { BasicQuestionForPreview, formatBasicQuestionForPreview } from 'utils/getAssessmentQuestionsMetadata';
import { loadStartedAssessmentQuestionIds } from 'utils/assessmentQuestionFunctions';
import { getRemoveableLosAndQuestionsFromTopicId, getRemoveableLosAndQuestions } from 'utils/topicLoFunctions';
import { saveLogMessage } from 'utils/saveLogMessage';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';

import { useAppDispatch, useAppSelector } from 'store';
import addLoToClassSession from 'store/actions/addLoToClassSession';
import createAssessment from 'store/actions/createAssessment';
import createUserTopicAndAddToClassSession from 'store/actions/createUserTopicAndAddToClassSession';
import moveTopicToClassSession from 'store/actions/moveTopicToClassSession';
import removeLoFromClassSession from 'store/actions/removeLoFromClassSession';
import retrieveSortedActiveCombinedQuestions from 'store/selectors/retrieveSortedActiveCombinedQuestions';
import retrieveEnrichedQuestionGroups from 'store/selectors/retrieveEnrichedQuestionGroups';
import retrieveBetterCoursePlannerWeeks from 'store/selectors/retrieveBetterCoursePlannerWeeks';
import moveCsloToAnotherTopic from 'store/actions/moveCsloToAnotherTopic';
import updateClassSession from 'store/actions/updateClassSession';
import { addClassSessionTopic, removeClassSessionTopic, reorderClassSessionTopic } from 'store/slices/activeTopicsThunks';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import BetterModal from 'shared-components/BetterModal/BetterModal';
import TopicLoPicker from './components/TopicLoPicker';
import ButtonRadioToggle from 'shared-components/ButtonRadioToggle/ButtonRadioToggle';
import CancelMovingButton from './components/CancelMovingButton';
import ConfirmRemoveTopicsLosQuestions, { RemovalConfirmationData } from 'instructor/components/ConfirmRemoveTopicsLosQuestions/ConfirmRemoveTopicsLosQuestions';
import CoursePlannerWeekContainer from './components/CoursePlannerWeekContainer';
import CoursePlannerClassRow from './components/CoursePlannerClassRow';
import CoursePlannerClassTopic from './components/CoursePlannerClassTopic';
import QuestionPreview from 'shared-components/QuestionPreview/QuestionPreview';
import TextButton from 'shared-components/BetterButton/TextButton';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';

import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { YesNo } from 'types/backend/shared.types';
import { SimplifiedErrorResponse } from 'api-next';
import { TabEnum } from '../AssessmentBuilderController/AssessmentBuilderController.types';
import './BetterCoursePlannerController.scss';

/**
 * Several components below the week level have some props in common, share them
 */
export interface SharedBCPProps {
  classSessionId: number
  classNumber: number
  handleCoursePlannerAction: HandleCoursePlannerAction
  isProcessing: boolean
  viewMode: BCPViewMode
}

export interface MovingTopic {
  classSessionId: number
  classSessionTopicId: number
  topicName?: string
}
export interface MovingLo {
  classSessionId: number
  csloId: number
  fromTopicName: string
  fromTopicId: number
}

interface BCPModalData {
  showModal: boolean
  classDate: string
  classSessionId: number
}

export enum BCPViewMode {
  Full = 'full',
  Compact = 'compact',
}

export enum BCPTopicCardMode {
  LoView = 'lo-view',
  TopicView = 'topic-view',
}

/**
 * A note on action nomenclature
 * 'Add-' & 'Remove-' prefixed actions trigger a UX interaction
 * 'Create-' & 'Delete-' prefixed actions trigger an API interaction
 */

export enum CoursePlannerAction {
  // Topics & LOs
  AddTopicsAndLosToClass = 'add-topics-and-los-to-class',
  AddClassSessionTopic = 'add-class-session-topic',
  AddLoToTopic = 'add-lo-to-topic',
  RemoveLoAndQuestionsFromClassSession = 'remove-lo-from-class-session',
  DeleteEmptyClassSessionLearningObjective = 'delete-lo-from-class-session',
  CreateCustomTopicAndAddToClassSession = 'create-custom-topic-and-add-to-class-session',
  RemoveTopicFromClassSession = 'remove-class-session-topic',
  DeleteEmptyClassSessionTopic = 'delete-empty-class-session-topic',
  // Study Path
  AddStudyPath = 'add-study-path',
  CreateStudyPathPlaceholder = 'create-study-path-placeholder',
  CreateStudyPathPlaceholderAndOpenSPB = 'create-study-path-placeholder-and-open-spb',
  // UI Action
  ShowAddTopicToClass = 'show-add-topic-to-class',
  PreviewLoContent = 'preview-lo-content',
  // Moving Topics
  StartMovingThisTopic = 'start-moving-this-topic',
  CancelMovingTopic = 'cancel-moving-topic',
  MoveTopicToThisClass = 'move-topic-to-this-class',
  // Moving LOs
  StartMovingThisLo = 'start-moving-this-lo',
  CancelMovingLo = 'cancel-moving-lo',
  MoveLoToThisTopic = 'move-lo-to-this-topic',
  // Class Day Labels
  UpdateClassDayLabel = 'update-class-day-label',
}

export interface CoursePlannerActionPayload {
  classDate?: string
  classSessionId: number
  topicId?: number
  csloId?: number
  learningObjectiveId?: number
  classType?: ClassTypeEnum
  stringValue?: string | null
}
export type HandleCoursePlannerAction = (action: CoursePlannerAction, payload: CoursePlannerActionPayload) => Promise<boolean>

const BetterCoursePlannerController = () => {
  const { pathname, search } = useLocation();
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const weeks = useAppSelector(retrieveBetterCoursePlannerWeeks);
  const assessmentQuestions = useAppSelector(store => store.active.assessmentQuestionMaps);
  const questions = useAppSelector(retrieveSortedActiveCombinedQuestions);
  const enrichedQuestionGroups = useAppSelector(retrieveEnrichedQuestionGroups);
  const courseAssessmentPreset = useAppSelector((store) => store.active.courseAssessmentPreset);
  const classSessions = useAppSelector((store) => store.active.classSessions);
  const classSessionLearningObjectives = useAppSelector(store => store.active.classSessionLearningObjectives);
  const { id: courseId, subjectId: primarySubjectId } = useAppSelector((store) => store.active.course);
  const userId = useAppSelector((store) => store.user.id);

  // Topic State
  const [addingTopicToClassSession, setAddingTopicToClassSession] = useState<number | null>(null);
  const [movingTopicFromClassSession, setMovingTopicFromClassSession] = useState<MovingTopic | null>(null); // tuple: [movingFromClassSession, movingTopicId]
  const [expandAllTopics, setExpandAllTopics] = useState<boolean | null>(null);
  const [reorderingTopicsOnClassSession, setReorderingTopicsOnClassSession] = useState<number | null>(null);
  // LO state
  const [movingLoFromClassSession, setMovingLoFromClassSession] = useState<MovingLo | null>(null); // tuple: [movingFromClassSession, movingTopicId]
  const [removalConfirmationData, setRemovalConfirmationData] = useState<RemovalConfirmationData | null>(null);
  // UI State
  const [addingStudyPathToClassSession, setAddingStudyPathToClassSession] = useState<number | null>(null);
  const [highlightLastAction, setHighlightLastAction] = useState<number>(-1);
  const [topicCardMode, setTopicCardMode] = useState(BCPTopicCardMode.LoView);
  const [expandAllWeeks, setExpandAllWeeks] = useState<boolean | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [viewMode, setViewMode] = useState(BCPViewMode.Compact);
  // Preview state
  const [previewQuestions, setPreviewQuestions] = useState<Array<BasicQuestionForPreview> | null>(null);

  const initModalData = {
    showModal: false,
    classDate: '',
    classSessionId: -1,
  };
  const [modalData, setModalData] = useReducer((prev: BCPModalData, next: Partial<BCPModalData>) => {
    // make it easy to reset all modalData on modal close
    if (prev.showModal && !next.showModal) {
      return initModalData;
    }
    return { ...prev, ...next };
  }, initModalData);
  const { showModal } = modalData;

  // centralized wrapper around the initialization of the remove confirmation to handle async fetch of started AQs
  const initRemovalConfirmation = async ({
    classSessionId,
    topicIdToRemove,
    csloIdsToRemove,
    assessmentQuestionIdsToRemove,
  }: {
    classSessionId: number
    topicIdToRemove?: number
    csloIdsToRemove: Array<number>
    assessmentQuestionIdsToRemove: Array<number>
  }) => {
    const startedAssessmentQuestionIds = await loadStartedAssessmentQuestionIds(assessmentQuestionIdsToRemove, courseId);
    setRemovalConfirmationData({
      classSessionId,
      topicIdToRemove,
      csloIdsToRemove,
      assessmentQuestionIdsToRemove,
      startedAssessmentQuestionIds,
    });
  };

  const handleCoursePlannerAction = async (action: CoursePlannerAction, payload: CoursePlannerActionPayload) => {
    await saveLogMessage(`BCP_action: ${action}`);
    console.debug('BetterCoursePlannerController handleCoursePlannerAction', action, payload);
    setHighlightLastAction(-1); // reset highlight on next action
    const { classSessionId } = payload;
    switch (action) {
      case CoursePlannerAction.AddClassSessionTopic: {
        const { topicId } = payload as { topicId: number };
        setIsProcessing(true);
        return dispatch(addClassSessionTopic({ classSessionId, topicId })).unwrap().then(() => {
          setIsProcessing(false);
          return true;
        });
      }
      case CoursePlannerAction.DeleteEmptyClassSessionTopic: {
        const { topicId: classSessionTopicId } = payload as { topicId: number };
        setIsProcessing(true);
        return dispatch(removeClassSessionTopic({ classSessionTopicId })).unwrap().then(() => {
          setIsProcessing(false);
          return true;
        });
      }
      case CoursePlannerAction.DeleteEmptyClassSessionLearningObjective: {
        const { csloId } = payload as { csloId: number };
        return dispatch(removeLoFromClassSession(csloId)).then(() => {
          setIsProcessing(false);
          return true;
        });
      }
      case CoursePlannerAction.RemoveTopicFromClassSession: {
        const { topicId: topicIdToRemove } = payload as { topicId: number };
        const { assessmentQuestionIdsOnlyInLos, csloIdsOnlyInThisClassSession, csloIds } = getRemoveableLosAndQuestionsFromTopicId({
          topicId: topicIdToRemove,
          classSessionId,
          classSessionLearningObjectives,
          assessmentQuestions,
          questions,
        });

        // if there are no cslos only in this class session, pass all topic cslo ids to removal confirmation
        const csloIdsToRemove = csloIdsOnlyInThisClassSession.length
          ? csloIdsOnlyInThisClassSession
          : csloIds;

        await initRemovalConfirmation({
          classSessionId,
          topicIdToRemove,
          csloIdsToRemove,
          assessmentQuestionIdsToRemove: assessmentQuestionIdsOnlyInLos,
        });
        return true;
      }
      case CoursePlannerAction.RemoveLoAndQuestionsFromClassSession: {
        const { csloId } = payload as { csloId: number };
        const { assessmentQuestionIdsOnlyInLos, csloIdsOnlyInThisClassSession, csloIds } = getRemoveableLosAndQuestions({
          csloIds: [csloId],
          classSessionId,
          classSessionLearningObjectives,
          assessmentQuestions,
          questions,
        });
        // if there are no cslos only in this class session, pass all topic cslo ids to removal confirmation
        const csloIdsToRemove = csloIdsOnlyInThisClassSession.length
          ? csloIdsOnlyInThisClassSession
          : csloIds;

        await initRemovalConfirmation({
          classSessionId,
          csloIdsToRemove,
          assessmentQuestionIdsToRemove: assessmentQuestionIdsOnlyInLos,
        });
        return true;
      }
      case CoursePlannerAction.AddStudyPath: {
        setAddingStudyPathToClassSession(payload.classSessionId);
        return true;
      }
      case CoursePlannerAction.AddLoToTopic: {
        const { topicId } = payload;
        // trigger the LO adding interface/modal, pre-filtering by topic
        return !!topicId;
      }
      case CoursePlannerAction.AddTopicsAndLosToClass: {
        setIsProcessing(true);
        const { learningObjectiveId } = payload as { learningObjectiveId: number };
        return dispatch(addLoToClassSession(learningObjectiveId, classSessionId, true)).then(() => {
          setIsProcessing(false);
          return true;
        });
      }
      case CoursePlannerAction.ShowAddTopicToClass: {
        setAddingTopicToClassSession(classSessionId);
        return true;
      }
      case CoursePlannerAction.CreateStudyPathPlaceholder:
      case CoursePlannerAction.CreateStudyPathPlaceholderAndOpenSPB: {
        const { classDate, stringValue: studyPathName } = payload as { stringValue: string; classDate: string };
        const summativeAssessmentData = formatStudyPathPlaceholderBasedOnPreset(classDate, studyPathName, courseAssessmentPreset);
        const summativeAssessmentForApi = formatAssessmentForApi(AssessTypeEnum.Summative, summativeAssessmentData, courseId);
        return dispatch(createAssessment(summativeAssessmentForApi)).then((createdAssessment) => {
          console.debug('Study Path Placeholder created', createdAssessment);
          if (action === CoursePlannerAction.CreateStudyPathPlaceholderAndOpenSPB) {
            // add csId to query to return to this scroll position
            const returnToLink = encodeURIComponent(`${pathname}${search}?csId=${classSessionId}`);
            const spbLink = `${InstructorCoursePath.AssessmentBuilder}?class-session=${classSessionId}&assessment=${createdAssessment.id}&tab=${TabEnum.SelectPrepQuestions}&returnTo=${returnToLink}`;
            history.push(spbLink);
          }
          return true;
        }).catch((error) => {
          console.error('CreateStudyPathPlaceholder error', error);
          return false;
        });
      }
      // Moving Topics
      case CoursePlannerAction.StartMovingThisTopic: {
        if (movingLoFromClassSession) {
          setMovingLoFromClassSession(null);
        }
        const { topicId: classSessionTopicId, stringValue: topicName = '' } = payload as { topicId: number; stringValue: string };
        !!classSessionTopicId && setMovingTopicFromClassSession({ classSessionId, classSessionTopicId, topicName });
        return true;
      }
      case CoursePlannerAction.CancelMovingTopic: {
        setMovingTopicFromClassSession(null);
        return true;
      }
      case CoursePlannerAction.MoveTopicToThisClass: {
        setIsProcessing(true);
        const { classSessionTopicId } = movingTopicFromClassSession as MovingTopic;
        // explicitly renaming this variable here for clarity
        const { classSessionId: targetClassSessionId } = payload;
        await dispatch(moveTopicToClassSession(classSessionTopicId, targetClassSessionId));
        setMovingTopicFromClassSession(null);
        setHighlightLastAction(classSessionTopicId);
        setIsProcessing(false);
        return true;
      }
      // Moving LOs
      case CoursePlannerAction.StartMovingThisLo: {
        if (movingTopicFromClassSession) {
          setMovingTopicFromClassSession(null);
        }
        const {
          csloId,
          stringValue: fromTopicName = '',
          topicId: fromTopicId,
        } = payload as { csloId: number; stringValue: string; topicId: number };
        setMovingLoFromClassSession({ classSessionId, csloId, fromTopicName, fromTopicId });
        return true;
      }
      case CoursePlannerAction.CancelMovingLo: {
        setMovingLoFromClassSession(null);
        return true;
      }
      case CoursePlannerAction.MoveLoToThisTopic: {
        const { csloId } = movingLoFromClassSession as MovingLo;
        const { topicId } = payload as { topicId: number };
        const result = await dispatch(moveCsloToAnotherTopic(csloId, classSessionId, topicId));
        const { error } = result as SimplifiedErrorResponse;
        if (error) {
          triggerConfirmationPrompt({
            title: 'Error moving LO',
            message: error.message,
            onConfirm: () => setMovingLoFromClassSession(null),
          });
        }
        setMovingLoFromClassSession(null);
        return true;
      }
      case CoursePlannerAction.CreateCustomTopicAndAddToClassSession: {
        const { stringValue: topicName } = payload as { stringValue: string };
        setIsProcessing(true);
        await dispatch(createUserTopicAndAddToClassSession({
          topicName,
          classSessionId,
          subjectId: primarySubjectId,
        })).then(() => {
          setAddingTopicToClassSession(null);
          setIsProcessing(false);
        });
        return true;
      }
      case CoursePlannerAction.UpdateClassDayLabel: {
        const { classType, stringValue: label } = payload;
        const sessionToUpdate = classSessions.find((session) => session.id === classSessionId);
        if (sessionToUpdate && classType && label !== undefined) {
          return dispatch(updateClassSession({ ...sessionToUpdate, classType, label })).then(() => {
            return true;
          }).catch((err) => {
            console.error(`UpdateClassDayLabel error ${JSON.stringify(err)}`);
            return false;
          });
        }
        return false;
      }
      case CoursePlannerAction.PreviewLoContent: {
        const { learningObjectiveId } = payload;
        if (!learningObjectiveId) {
          console.debug('PreviewLoContent missing learningObjectiveId');
          return false;
        }
        // NOTE: we need to filter these questions because store.active.questions can include inactive questions, which cannot be loaded by l8y
        const activeQuestions = questions.filter((gq) => gq.active === YesNo.Yes);
        const previewQs = activeQuestions.reduce((acc, q) => {
          if (!acc.find((accQ) => accQ.questionId === q.id) && q.learningObjectiveIds.includes(learningObjectiveId)) {
            if (enrichedQuestionGroups.find((g) => g.groupQuestionIds.includes(q.id))) {
              // we want the preview to include all questions in the group even if they are not all tagged with the preview LO
              // note also this will need to be refactored to support a question being in multiple groups
              const group = enrichedQuestionGroups.find((g) => g.groupQuestionIds.includes(q.id));
              if (group) {
                // Now 'group' is guaranteed to be defined within this block
                const questionGroupId = group.questionGroupId;
                const groupQuestions = enrichedQuestionGroups.find((g) => g.questionGroupId === questionGroupId)?.groupQuestions || [];
                const activeGroupQuestions = groupQuestions.filter((gq) => gq.active === YesNo.Yes);
                acc = [...activeGroupQuestions.map(formatBasicQuestionForPreview), ...acc]; // group question first
              }
            } else {
              acc.push(formatBasicQuestionForPreview(q));
            }
          }
          return acc;
        }, [] as Array<BasicQuestionForPreview>);
        previewQs.length && setPreviewQuestions(previewQs);
        return true;
      }
      default: return false;
    }
  };

  const handleTopicReorder = async (classSessionTopicId: number, previousId: number) => {
    await dispatch(reorderClassSessionTopic({ classSessionTopicId, previousId }));
    setHighlightLastAction(classSessionTopicId);
  };

  return (
    <div className="better-course-planner">
      <div className="bcp__toolbar">
        <div className="bcp__toolbar__left">
          <Link to={`/instructor/course/${courseId}/${InstructorCoursePath.CoursePlanner}`}>
            Go to simple Course Planner
          </Link>
        </div>
        <div className="bcp__toolbar__right">
          <ButtonRadioToggle
            className="bcp__toolbar__toggle"
            items={[
              {
                label: 'LO View',
                onClick: () => {},
                id: BCPTopicCardMode.LoView,
              },
              {
                label: 'Topic View',
                onClick: () => {},
                id: BCPTopicCardMode.TopicView,
              },
            ]}
            name="toggle-bcp-mode"
            selectedItemId={topicCardMode}
            onChange={(val) => {
              setExpandAllTopics(val === BCPTopicCardMode.LoView);
              setTopicCardMode(val as BCPTopicCardMode);
            }}
          />
        </div>
        {false && ( // currently unused for beta but may return in future
          <>
            {viewMode === BCPViewMode.Compact && (
              <div className="bcp__toolbar__item">
                <button className="bcp__toolbar__item__expand-collapse-weeks" onClick={() => setExpandAllWeeks(prev => prev === null ? false : !prev)}>
                  {expandAllWeeks === null || expandAllWeeks === true ? 'Collapse' : 'Expand'} All Weeks
                </button>
              </div>
            )}
            <div className="bcp__toolbar__item">
              <ToggleSwitch
                id="bcp-compact-mode"
                label="Compact Mode"
                checked={viewMode === BCPViewMode.Compact}
                onChange={(checked) => setViewMode(checked ? BCPViewMode.Compact : BCPViewMode.Full)}
              />
            </div>
          </>
        )}
      </div>
      <div className="bcp__week-list">
        {weeks.map((week) => {
          const { weekNumber, weekClassSessions } = week;
          return (
            <CoursePlannerWeekContainer
              key={`week_${weekNumber}`}
              viewMode={viewMode}
              week={week}
              expanded={expandAllWeeks}
              onExpandToggle={() => setExpandAllWeeks(null)}
            >
              <div className="bcp__week-body__classes">
                {weekClassSessions.map((bcs) => {
                  const { classDate, classNumber, id: classSessionId, topics, label } = bcs;
                  const sharedClassSessionProps = {
                    classSessionId,
                    classNumber,
                    handleCoursePlannerAction,
                    isProcessing,
                    viewMode,
                  };
                  const showTopicPicker = addingTopicToClassSession === classSessionId;
                  const reorderingTopicsInThisClassSession = reorderingTopicsOnClassSession === classSessionId;
                  return (
                    <CoursePlannerClassRow
                      {...sharedClassSessionProps}
                      addingStudyPath={addingStudyPathToClassSession}
                      classSession={bcs}
                      classDate={classDate}
                      key={`${classDate}_${classSessionId}`}
                      label={label}
                      movingTopic={movingTopicFromClassSession}
                    >
                      {topics.map((topic, idx) => {
                        const previousItem = topics[idx - 1];
                        const nextItem = topics[idx + 1];
                        const previousIdForMoveUp = idx === 1 ? 0 : topics[idx - 2]?.classSessionTopicId;
                        const previousIdForMoveDown = nextItem?.classSessionTopicId;
                        return (
                          <CoursePlannerClassTopic
                            {...sharedClassSessionProps}
                            addingItems={showTopicPicker}
                            key={`${classSessionId}_${topic.id}`}
                            highlight={[topic.id, topic.classSessionTopicId].includes(highlightLastAction)}
                            cardLabel={viewMode === BCPViewMode.Full ? topic.name : (
                              <>{topic.name} <small><em>{topic.courseLearningObjectives.length} LOs</em></small></>
                            )}
                            movingLo={movingLoFromClassSession}
                            movingTopic={movingTopicFromClassSession}
                            toggleReorderingTopics={() => setReorderingTopicsOnClassSession(reorderingTopicsInThisClassSession ? null : classSessionId)}
                            reorderingData={{
                              canMoveUp: !!previousItem,
                              canMoveDown: !!nextItem,
                              enableReordering: topics.length > 1,
                              isReordering: reorderingTopicsInThisClassSession,
                              moveTopicUp: () => handleTopicReorder(topic.classSessionTopicId, previousIdForMoveUp),
                              moveTopicDown: () => handleTopicReorder(topic.classSessionTopicId, previousIdForMoveDown),
                            }}
                            topic={topic}
                            expanded={reorderingTopicsInThisClassSession ? false : expandAllTopics}
                            onExpandToggle={() => setExpandAllTopics(null)}
                          />
                        );
                      })}
                      {showTopicPicker && (
                        <TopicLoPicker
                          {...sharedClassSessionProps}
                          classSession={bcs}
                          hideTopicPicker={() => setAddingTopicToClassSession(null)}
                        />
                      )}
                    </CoursePlannerClassRow>
                  );
                })}
              </div>
            </CoursePlannerWeekContainer>
          );
        })}
      </div>
      <BetterModal
        isShowing={!!previewQuestions}
        hide={() => setPreviewQuestions(null)}
      >
        <QuestionPreview
          userId={userId}
          questions={previewQuestions as Array<BasicQuestionForPreview>}
        />
      </BetterModal>
      <BetterModal className="bcp__modal" isShowing={showModal} hide={() => setModalData({ showModal: false })}>
        <CoursePlannerModal
          modalData={modalData}
          handleClose={() => setModalData({ showModal: false })}
          handleCoursePlannerAction={handleCoursePlannerAction}
        />
      </BetterModal>
      {!!removalConfirmationData && (
        <ConfirmRemoveTopicsLosQuestions
          handleExit={() => setRemovalConfirmationData(null)}
          removalConfirmationData={removalConfirmationData}
        />
      )}
      {!!movingTopicFromClassSession && (
        <CancelMovingButton
          btnText={`Cancel Moving Topic - ${movingTopicFromClassSession.topicName}`}
          message={`Moving Topic from Class ${(classSessions.find((cs) => cs.id === movingTopicFromClassSession.classSessionId) as { classNumber: number }).classNumber}`}
          onClick={() => setMovingTopicFromClassSession(null)}
        />
      )}
      {!!movingLoFromClassSession && (
        <CancelMovingButton
          btnText="Cancel Moving LO"
          message={`Moving LO from ${movingLoFromClassSession.fromTopicName}`}
          onClick={() => setMovingLoFromClassSession(null)}
        />
      )}
    </div>
  );
};

// Modal skeleton which will eventually be used for LO selector, etc
const CoursePlannerModal = ({
  handleCoursePlannerAction,
  handleClose,
  modalData,
}: {
  handleClose: () => void
  handleCoursePlannerAction: HandleCoursePlannerAction
  modalData: BCPModalData
}) => {
  const { classSessionId } = modalData;
  return (
    <div className="bcp__modal">
      <div className="bcp__modal__content">
        {JSON.stringify(modalData, null, 2)}
      </div>
      <div className="bcp__modal__actions">
        <BetterButton
          className="new-button"
          text="Add Items"
          primary
          onClick={() => handleCoursePlannerAction(CoursePlannerAction.CreateStudyPathPlaceholder, { classSessionId })}
        />
        <BetterButton className="new-button" text="Save & Exit" secondary onClick={() => {}} />
        <TextButton onClick={() => handleClose()}>Cancel</TextButton>
      </div>
    </div>
  );
};

export default BetterCoursePlannerController;
