import { feathers } from '@feathersjs/feathers';
import authentication from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';
import axios from 'axios';

const restClient = rest(process.env.REACT_APP_API_NEXT_URL);
const client = feathers();

export const addExtraQueryParams = (extraParams: { ltiUserId: string }) => {
  const interceptorId = axios.interceptors.request.use((config) => {
    config.params = { ...config.params, ...extraParams };
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  window.sessionStorage.setItem('interceptorId', JSON.stringify(interceptorId));
};

client.configure(restClient.axios(axios));

client.configure(authentication({ storageKey: 'auth' }));

export default client;
