import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { FaPlusCircle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { DateTime } from 'luxon';

import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { LoSelectorContext } from '../../CourseLoSelectorController';
import { FeatureFlags } from 'App';

import { useAppSelector } from 'store';
import retrieveBetterClassSessions, { BetterClassSession, BetterClassSessionTopic } from 'store/selectors/retrieveBetterClassSessions';
import SimpleInput from 'shared-components/SimpleInput/SimpleInput';
import ClassSessionLoListTopic from './ClassSessionLoListTopic';

import { DragTypeEnum, LoActionEnum, LoActionDispatch } from '../../CourseLoSelector.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { TopicApi } from 'types/backend/topics.types';
import './ClassSessionLoList.scss';

function ClassSessionLoList({
  currentClassSession,
  loAction,
  onAddCustomTopic,
  onChangeLoSortWithinTopic,
  onChangeTopicSort,
  onMoveLoToTopic,
  onMoveTopic,
  onRemoveTopic,
  updateUserTopic,
}: {
  currentClassSession: BetterClassSession
  loAction: LoActionDispatch
  onAddCustomTopic: (name: string) => void
  onChangeLoSortWithinTopic: (sourceCslo: ClassSessionLearningObjectiveApi, previousId: number) => Promise<void>
  onChangeTopicSort: (classSessionTopicId: number, previousId: number) => Promise<void>
  onMoveLoToTopic: (csloToMove: ClassSessionLearningObjectiveApi, destTopicId: number, previousId: number) => Promise<void>
  onMoveTopic: (classSessionTopicId: number, newClassSessionId: number) => void
  onRemoveTopic: (topicId: number) => void
  updateUserTopic: (updatedTopic: TopicApi) => void
}) {
  const flags = useContext(FeatureFlags);
  const { setIsProcessing } = useContext(LoSelectorContext);
  const classSessionLearningObjectives = useAppSelector((store) => store.active.classSessionLearningObjectives);
  const classSessions = useAppSelector(retrieveBetterClassSessions);
  const availableClassSessions = classSessions.filter(cs => cs.classType === ClassTypeEnum.Normal);

  const { id: currentClassSessionId, classDate, classNumber, topics: classSessionTopics } = currentClassSession;

  const [selectedClassSessionId, setSelectedClassSessionId] = useClassSessionQuery(classSessions);
  const activeClassSessionIndex = availableClassSessions.findIndex(({ id }) => id === selectedClassSessionId);
  const { id: prevClassSessionId } = availableClassSessions[activeClassSessionIndex - 1] || {};
  const { id: nextClassSessionId } = availableClassSessions[activeClassSessionIndex + 1] || {};

  const [creatingNewTopic, setCreatingNewTopic] = useState(false);

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    if (!destination) {
      return; //bail if values are missing. This usually happens if the drag was released in a random spot
    }
    const { index: destIndex } = destination;
    const { index: sourceIndex } = source;
    setIsProcessing(true);
    if (result.type === DragTypeEnum.MoveTopic) {
      const draggedTopicId = parseInt(result.draggableId, 10);
      const { classSessionTopicId: draggedClassSessionTopicId } = classSessionTopics.find(cst => cst.id === draggedTopicId) as BetterClassSessionTopic;

      let previousId = 0;
      if (destIndex > 0) {
        const offset = destIndex < sourceIndex ? destIndex - 1 : destIndex;
        previousId = classSessionTopics[offset].classSessionTopicId;
      }
      await onChangeTopicSort(draggedClassSessionTopicId, previousId);
      setIsProcessing(false);
    } else if (result.type === DragTypeEnum.MoveLo) {
      const draggedLoId = parseInt(result.draggableId, 10);
      const sourceTopicId = parseInt(source.droppableId, 10);
      const destTopicId = parseInt(destination.droppableId, 10);
      const cslosForDestTopic = classSessionLearningObjectives.filter(cslo => cslo.classSessionId === currentClassSession.id && cslo.topicId === destTopicId);
      const sourceCslo = classSessionLearningObjectives.find(cslo => cslo.classSessionId === currentClassSession.id && cslo.learningObjectiveId === draggedLoId) as ClassSessionLearningObjectiveApi;
      let previousId;
      if (sourceTopicId === destTopicId) {
        if (destIndex < sourceIndex) {
          previousId = destIndex > 0 ? cslosForDestTopic[destIndex - 1].id : 0; //set previousId for reference in API call
        } else {
          previousId = cslosForDestTopic[destIndex].id;
        }
        await onChangeLoSortWithinTopic(sourceCslo, previousId);
      } else {
        previousId = destIndex === 0 ? 0 : cslosForDestTopic[destIndex - 1].id;
        await onMoveLoToTopic(sourceCslo, destTopicId, previousId);
      }
      setIsProcessing(false);
    }
  }

  return (
    <div className="lo-sel-card">
      <div className="lo-sel-card__info">
        <button
          className="lo-sel-card__prev"
          disabled={!prevClassSessionId}
          onClick={() => setSelectedClassSessionId(prevClassSessionId)}
        >
          <FaChevronLeft />
        </button>
        <div className="lo-sel-card__session">
          <div className="lo-sel-card__class-number">Class {classNumber}</div>
          <span>{DateTime.fromISO(classDate).toFormat(DateFormatEnum.WeekdayWithLongDateFullYear)}</span>
        </div>
        <button
          className="lo-sel-card__next"
          disabled={!nextClassSessionId}
          onClick={() => setSelectedClassSessionId(nextClassSessionId)}
        >
          <FaChevronRight />
        </button>
      </div>
      <div className="lo-sel-card__board">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" type="moveTopic">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {classSessionTopics.map((csdt, cstIndex) => (
                  <ClassSessionLoListTopic
                    classSessions={availableClassSessions}
                    currentClassSessionId={currentClassSessionId}
                    currentTopic={csdt}
                    key={`topic-card-${csdt.classSessionTopicId}`}
                    loAction={loAction}
                    nextClassSessionId={nextClassSessionId}
                    onMoveTopic={onMoveTopic}
                    onRemoveTopic={onRemoveTopic}
                    prevClassSessionId={prevClassSessionId}
                    topicIndex={cstIndex}
                    topicLos={csdt.courseLearningObjectives}
                    updateUserTopic={updateUserTopic}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <button
        className="lo-sel-card__add-topic"
        onClick={() => setCreatingNewTopic(true)}
      >
        <FaPlusCircle className="lo-sel-card__icon" />
        {creatingNewTopic ? (
          <SimpleInput
            className="custom-topic__input"
            key='customTopic'
            defaultValue=""
            placeholder='Add custom topic (enter to save)'
            onSave={onAddCustomTopic}
            setIsEditing={setCreatingNewTopic}
          />
        ) : (
          <div>Add Custom Topic</div>
        )}
      </button>
      {flags.enableCustomLos && (
        <button
          className="lo-sel-card__add-custom-lo"
          onClick={() => loAction(LoActionEnum.CreateLo)}
        >
          <FaPlusCircle className="lo-sel-card__icon" />
          <div>Add Custom Learning Objective</div>
        </button>
      )}
    </div>
  );
}

ClassSessionLoList.propTypes = {
  loAction: PropTypes.func.isRequired,
  onAddCustomTopic: PropTypes.func,
  onChangeLoSortWithinTopic: PropTypes.func,
  onChangeTopicSort: PropTypes.func,
  onMoveLoToTopic: PropTypes.func,
  onMoveTopic: PropTypes.func,
  onRemoveTopic: PropTypes.func,
  updateUserTopic: PropTypes.func,
};

export default ClassSessionLoList;
