import { AlignmentItem } from 'instructor/controllers/Course/DailyPlannerController/DailyPlannerAlignmentItem';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { MappedAssessmentQuestion } from 'types/common.types';

export interface QuestionDataHash {
  [key: string]: {
    count: number
    assessmentName: string
  }
}

// break the AQMs down by category for the 3 subsections of the expando
export const calculateAqmBreakdown = (aqms: Array<MappedAssessmentQuestion>) => aqms.reduce((acc, cur) => {
  const { _derived: { assessmentData } } = cur;
  const { assessType } = assessmentData;
  switch (assessType) {
    case AssessTypeEnum.Preclass:
    case AssessTypeEnum.Readiness: {
      acc[AssessTypeEnum.Preclass].push(cur);
      break;
    }
    case AssessTypeEnum.Homework: {
      acc[AssessTypeEnum.Homework].push(cur);
      break;
    }
    case AssessTypeEnum.Prep:
    case AssessTypeEnum.PracticeTest: {
      acc[AssessTypeEnum.Summative].push(cur);
      break;
    }
  }
  return acc;
}, {
  [AssessTypeEnum.Preclass]: [] as Array<MappedAssessmentQuestion>,
  [AssessTypeEnum.Homework]: [] as Array<MappedAssessmentQuestion>,
  [AssessTypeEnum.Summative]: [] as Array<MappedAssessmentQuestion>,
});

export const calculateCloData = (courseLearningObjectives: Array<EnrichedCourseLearningObjective>, assessmentQuestionMaps: Array<MappedAssessmentQuestion>) => courseLearningObjectives.reduce((acc, clo) => {
  const assessmentQuestionMapsWithCloId = assessmentQuestionMaps.filter(({ assessmentId, _derived }) => {
    const cloIds = _derived.courseLearningObjectives.map(({ id }) => id);
    return cloIds.includes(clo.id);
  });
  return [
    ...acc,
    {
      clo,
      loString: clo._derived.loNumber,
      aqms: assessmentQuestionMapsWithCloId,
    },
  ] as Array<AlignmentItem>;
  return acc;
}, [] as Array<AlignmentItem>);

export const getQuestionHashFromAqmBreakdown = (aqmBreakdown: {
  [AssessTypeEnum.Preclass]: Array<MappedAssessmentQuestion>
  [AssessTypeEnum.Homework]: Array<MappedAssessmentQuestion>
  [AssessTypeEnum.Summative]: Array<MappedAssessmentQuestion>
}) => {
  const reduceAqmsToCountsByQuestion = (acc: QuestionDataHash, cur: MappedAssessmentQuestion) => {
    const { assessmentId, _derived: { assessmentData } } = cur;
    const { count: existingCount = 0 } = acc[assessmentId] || {};
    acc[assessmentId] = {
      count: existingCount + 1,
      assessmentName: assessmentData.name,
    };
    return acc;
  };

  const cloAqmsForPreclass = aqmBreakdown[AssessTypeEnum.Preclass];
  const cloAqmsForHomework = aqmBreakdown[AssessTypeEnum.Homework];
  const cloAqmsForSummative = aqmBreakdown[AssessTypeEnum.Summative];
  // sort so that prep comes before practice test
  const sortedCloAqmsForSummative = cloAqmsForSummative.sort((a, b) => {
    if (a._derived.assessmentData.assessType === AssessTypeEnum.Prep && b._derived.assessmentData.assessType === AssessTypeEnum.PracticeTest) {
      return -1;
    }
    if (a._derived.assessmentData.assessType === AssessTypeEnum.PracticeTest && b._derived.assessmentData.assessType === AssessTypeEnum.Prep) {
      return 1;
    }
    return 0;
  });

  const preclassQuestionsByClo = cloAqmsForPreclass.reduce(reduceAqmsToCountsByQuestion, {} as QuestionDataHash);
  const homeworkQuestionsByClo = cloAqmsForHomework.reduce(reduceAqmsToCountsByQuestion, {} as QuestionDataHash);
  const summativeQuestionsByClo = sortedCloAqmsForSummative.reduce(reduceAqmsToCountsByQuestion, {} as QuestionDataHash);
  return {
    preclassQuestionsByClo,
    homeworkQuestionsByClo,
    summativeQuestionsByClo,
  };
};

// filling the summative pie needs both prep and PT questions, so this function takes the generalized Summative AQM group and returns a boolean whether the pie should fill
export const summativeShouldFill = (aqmArray: Array<MappedAssessmentQuestion>) => {
  const prepAqms = aqmArray.filter(({ _derived: { assessmentData: { assessType } } }) => assessType === AssessTypeEnum.Prep);
  const practiceTestAqms = aqmArray.filter(({ _derived: { assessmentData: { assessType } } }) => assessType === AssessTypeEnum.PracticeTest);
  return !!prepAqms.length && !!practiceTestAqms.length;
};
