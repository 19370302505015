import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addClassSessionTopic,
  addUserTopic,
  removeClassSessionTopic,
  reorderClassSessionTopic,
} from 'store/slices/activeTopicsThunks';
import { CourseApi } from 'types/backend/courses.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { AssessmentApiBase, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { ClassSessionIclrApi } from 'types/backend/classSessionIclr.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { EnrollmentAssessmentApi } from 'types/backend/enrollmentAssessments.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { QuestionGroupQuestionWithGroupApi } from 'types/backend/questionGroupQuestions.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { StudentStudyPathApi } from 'types/backend/studentStudyPaths.types';
import { StudyPathApi } from 'types/backend/studyPaths.types';
import { UserApi, UserApiIdNameEmail } from 'types/backend/users.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';
import { TopicApi } from 'types/backend/topics.types';
import { UnitApi } from 'types/backend/units.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { IclrApi } from 'types/backend/iclr.types';
import { OoclrApi } from 'types/backend/ooclr.types';

export interface Active {
  assessmentQuestionMaps: Array<AssessmentQuestionApi>
  assessments: Array<AssessmentApiBase>
  classSessionIclrs: Array<ClassSessionIclrApi>
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>
  classSessionOoclrs: Array<ClassSessionOoclrApi>
  classSessions: Array<ClassSessionApi>
  classSessionTopics: Array<ClassSessionTopicApi>
  course: CourseApi
  courseAssessmentPreset: CourseAssessmentPresetApi
  currentClassSession: ClassSessionApi | null
  enrollmentAssessments: Array<EnrollmentAssessmentApi>
  iclrs: Array<IclrApi>
  instructors: Array<UserApiIdNameEmail>
  learningObjectives: Array<LearningObjectiveApi>
  ooclrs: Array<OoclrApi>
  questionGroups: Array<QuestionGroupQuestionWithGroupApi>
  studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas>
  studentAssessments: Array<StudentAssessmentApi>
  enrollment: EnrollmentApi
  students: Array<UserApi>
  studentStudyPath: StudentStudyPathApi
  studyPath: Required<StudyPathApi>
  summativeAssessmentSupplements: Array<SummativeAssessmentSupplementsApi>
  templateLearningObjectives: Array<any>
  templateQuestions: Array<QuestionApiOut>
  topics: Array<TopicApi>
  units: Array<UnitApi>
  userQuestions: Array<QuestionApiOut>
}

const defaultActiveState: Active = {
  assessmentQuestionMaps: [],
  assessments: [],
  classSessionIclrs: [],
  classSessionLearningObjectives: [],
  classSessionOoclrs: [],
  classSessions: [],
  classSessionTopics: [],
  course: {} as CourseApi,
  courseAssessmentPreset: {} as CourseAssessmentPresetApi,
  currentClassSession: null,
  enrollmentAssessments: [],
  iclrs: [],
  instructors: [],
  learningObjectives: [],
  ooclrs: [],
  questionGroups: [],
  studentAssessmentQuestions: [],
  studentAssessments: [],
  enrollment: {} as EnrollmentApi,
  students: [],
  studentStudyPath: {} as StudentStudyPathApi,
  studyPath: {} as Required<StudyPathApi>,
  summativeAssessmentSupplements: [],
  templateLearningObjectives: [],
  templateQuestions: [],
  topics: [],
  units: [],
  userQuestions: [],
};

const activeSlice = createSlice({
  name: 'active',
  initialState: defaultActiveState,
  reducers: {
    setActiveInitialData: (active, action: PayloadAction<Partial<Active>>) => {
      return {
        ...active,
        ...action.payload,
      };
    },
    setActive: (active, action: PayloadAction<Active>) => {
      return action.payload;
    },
    setActiveTemplateQuestions: (active, action: PayloadAction<Array<QuestionApiOut>>) => {
      return {
        ...active,
        templateQuestions: action.payload,
      };
    },
    editActiveTemplateQuestion: (active, action: PayloadAction<{ questionId: number; delta: { learningObjectiveIds: Array<number> } }>) => {
      const updatedTemplateQuestions = active.templateQuestions.map((tq) => {
        return tq.id === action.payload.questionId
          ? { ...tq, ...action.payload.delta }
          : tq;
      });
      return {
        ...active,
        templateQuestions: updatedTemplateQuestions,
      };
    },
    updateCourseAssessmentPreset: (active, action: PayloadAction<CourseAssessmentPresetApi>) => {
      return {
        ...active,
        courseAssessmentPreset: action.payload,
      };
    },
    setActiveAssessments: (active, action: PayloadAction<Array<AssessmentApiBase>>) => {
      return {
        ...active,
        assessments: action.payload,
      };
    },
    editActiveAssessment: (active, action: PayloadAction<{ id: string; delta: AssessmentApiBase | SummativeAssessmentApi }>) => {
      return {
        ...active,
        assessments: active.assessments.map((assessment) => {
          return assessment.id === action.payload.id
            ? { ...assessment, ...action.payload.delta }
            : assessment;
        }),
      };
    },
    editActiveSummativeAssessment: (active, action: PayloadAction<{ id: string; delta: SummativeAssessmentApi }>) => {
      return {
        ...active,
        assessments: active.assessments.map((assessment) => {
          return assessment.id === action.payload.id
            ? { ...assessment, ...action.payload.delta }
            : assessment;
        }),
      };
    },
    setActiveCourse: (active, action: PayloadAction<CourseApi>) => {
      return {
        ...active,
        course: action.payload,
      };
    },
    setActiveClassSessions: (active, action: PayloadAction<Array<ClassSessionApi>>) => {
      return {
        ...active,
        classSessions: action.payload,
      };
    },
    updateActiveEnrollment: (active, action: PayloadAction<EnrollmentApi>) => {
      return {
        ...active,
        enrollment: {
          ...active.enrollment,
          ...action.payload,
        },
      };
    },
    editActiveUserTopic: (active, action: PayloadAction<{ id: number; delta: TopicApi }>) => {
      return {
        ...active,
        topics: active.topics.map((topic) => {
          return topic.id === action.payload.id
            ? { ...topic, ...action.payload.delta }
            : topic;
        }),
      };
    },
    setActiveUserQuestions: (active, action: PayloadAction<Array<QuestionApiOut>>) => {
      return {
        ...active,
        userQuestions: action.payload,
      };
    },
    addActiveUserQuestion: (active, action: PayloadAction<QuestionApiOut>) => {
      return {
        ...active,
        userQuestions: [
          ...active.userQuestions,
          action.payload,
        ],
      };
    },
    editActiveUserQuestion: (active, action: PayloadAction<QuestionApiOut>) => {
      return {
        ...active,
        userQuestions: active.userQuestions.map((userQuestion) => {
          return userQuestion.id === action.payload.id
            ? { ...userQuestion, ...action.payload }
            : userQuestion;
        }),
      };
    },
    editActiveUserTopicAndClassSessionTopic: (active, action: PayloadAction<{
      id: number
      delta: {
        newTopic: TopicApi
        newCst: ClassSessionTopicApi
      }
    }>) => {
      return {
        ...active,
        topics: active.topics.map((topic) => {
          return topic.id === action.payload.id
            ? { ...topic, ...action.payload.delta.newTopic }
            : topic;
        }),
        classSessionTopics: active.classSessionTopics.map((cst) => {
          return cst.id === action.payload.id
            ? { ...cst, ...action.payload.delta.newCst }
            : cst;
        }),
      };
    },
    addActiveTopic: (active, action: PayloadAction<TopicApi>) => {
      return {
        ...active,
        topics: [...active.topics, action.payload],
      };
    },
    setActiveTopics: (active, action: PayloadAction<Array<TopicApi>>) => {
      return {
        ...active,
        topics: action.payload,
      };
    },
    addActiveClassSessionTopic: (active, action: PayloadAction<ClassSessionTopicApi>) => {
      return {
        ...active,
        classSessionTopics: [
          ...active.classSessionTopics,
          action.payload,
        ],
      };
    },
    addActiveLearningObjective: (active, action: PayloadAction<LearningObjectiveApi>) => {
      return {
        ...active,
        learningObjectives: [
          ...active.learningObjectives,
          action.payload,
        ],
      };
    },
    updateActiveLearningObjective: (active, action: PayloadAction<LearningObjectiveApi>) => {
      return {
        ...active,
        learningObjectives: active.learningObjectives.map((lo) => lo.id === action.payload.id
          ? action.payload
          : lo
        ),
      };
    },
    removeActiveClassSessionTopics: (active, action: PayloadAction<{ id: number }>) => {
      return {
        ...active,
        classSessionTopics: active.classSessionTopics.filter((cst) => cst.id !== action.payload.id),
      };
    },
    setActiveClassSessionTopics: (active, action: PayloadAction<Array<ClassSessionTopicApi>>) => {
      return {
        ...active,
        classSessionTopics: action.payload,
      };
    },
    setActiveClassSessionLearningObjectives: (active, action: PayloadAction<Array<ClassSessionLearningObjectiveApi>>) => {
      return {
        ...active,
        classSessionLearningObjectives: action.payload,
      };
    },
    addActiveClassSessionLearningObjectives: (active, action: PayloadAction<ClassSessionLearningObjectiveApi>) => {
      return {
        ...active,
        classSessionLearningObjectives: [
          ...active.classSessionLearningObjectives,
          action.payload,
        ],
      };
    },
    removeActiveClassSessionLearningObjectives: (active, action: PayloadAction<{ id: number }>) => {
      return {
        ...active,
        classSessionLearningObjectives: active.classSessionLearningObjectives.filter((cslo) => cslo.id !== action.payload.id),
      };
    },
    setActiveCurrentClassSession: (active, action: PayloadAction<ClassSessionApi>) => {
      return {
        ...active,
        currentClassSession: action.payload,
      };
    },
    setStudentStudyPath: (active, action: PayloadAction<StudentStudyPathApi>) => {
      return {
        ...active,
        studentStudyPath: action.payload,
      };
    },
    setStudentAssessments: (active, action: PayloadAction<Array<StudentAssessmentApi>>) => {
      return {
        ...active,
        studentAssessments: action.payload,
      };
    },
    setStudentAssessmentsAndStudentAssessmentQuestionsAndAqms: (active, action: PayloadAction<{
      studentAssessments: Array<StudentAssessmentApi>
      studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas>
      assessmentQuestionMaps: Array<AssessmentQuestionApi>
    }>) => {
      return {
        ...active,
        studentAssessments: action.payload.studentAssessments,
        studentAssessmentQuestions: action.payload.studentAssessmentQuestions,
        assessmentQuestionMaps: action.payload.assessmentQuestionMaps,
      };
    },
    setStudyPath: (active, action: PayloadAction<Required<StudyPathApi>>) => {
      return {
        ...active,
        studyPath: action.payload,
      };
    },
    addActiveSummativeAssessmentSupplement: (active, action: PayloadAction<SummativeAssessmentSupplementsApi>) => {
      return {
        ...active,
        summativeAssessmentSupplements: [
          ...active.summativeAssessmentSupplements,
          action.payload,
        ],
      };
    },
    removeActiveAssessmentAndMaps: (active, action: PayloadAction<{ id: string }>) => {
      const updatedAssessments = active.assessments.filter((a) => a.id !== action.payload.id);
      const updatedAssessmentQuestionMaps = active.assessmentQuestionMaps.filter((aqm) => aqm.assessmentId !== action.payload.id);
      return {
        ...active,
        assessments: updatedAssessments,
        assessmentQuestionMaps: updatedAssessmentQuestionMaps,
      };
    },
    removeSummativeAssessmentSupplement: (active, action: PayloadAction<Pick<SummativeAssessmentSupplementsApi, 'id'>>) => {
      return {
        ...active,
        summativeAssessmentSupplements: active.summativeAssessmentSupplements.filter((s) => s.id !== action.payload.id),
      };
    },
    addActiveAssessment: (active, action: PayloadAction<AssessmentApiBase>) => {
      return {
        ...active,
        assessments: [
          ...active.assessments,
          action.payload,
        ],
      };
    },


    addActiveIclr: (active, action: PayloadAction<IclrApi>) => {
      return {
        ...active,
        iclrs: [
          ...active.iclrs,
          action.payload,
        ],
      };
    },
    editActiveIclr: (active, action: PayloadAction<{ id: number; delta: IclrApi }>) => {
      return {
        ...active,
        iclrs: active.iclrs.map((iclr) => {
          return iclr.id === action.payload.id
            ? { ...iclr, ...action.payload.delta }
            : iclr;
        }),
      };
    },
    removeActiveIclr: (active, action: PayloadAction<Pick<IclrApi, 'id'>>) => {
      return {
        ...active,
        iclrs: active.iclrs.filter((iclr) => iclr.id !== action.payload.id),
      };
    },
    addActiveClassSessionIclrs: (active, action: PayloadAction<Array<ClassSessionIclrApi>>) => {
      return {
        ...active,
        classSessionIclrs: [
          ...active.classSessionIclrs,
          ...action.payload,
        ],
      };
    },
    removeActiveClassSessionIclrs: (active, action: PayloadAction<{ ids: Array<number> }>) => {
      return {
        ...active,
        classSessionIclrs: active.classSessionIclrs.filter((csIclr) => !action.payload.ids.includes(csIclr.id)),
      };
    },


    addActiveOoclr: (active, action: PayloadAction<OoclrApi>) => {
      return {
        ...active,
        ooclrs: [
          ...active.ooclrs,
          action.payload,
        ],
      };
    },
    editActiveOoclr: (active, action: PayloadAction<{ id: number; delta: OoclrApi }>) => {
      return {
        ...active,
        ooclrs: active.ooclrs.map((iclr) => {
          return iclr.id === action.payload.id
            ? { ...iclr, ...action.payload.delta }
            : iclr;
        }),
      };
    },
    removeActiveOoclr: (active, action: PayloadAction<Pick<OoclrApi, 'id'>>) => {
      return {
        ...active,
        ooclrs: active.ooclrs.filter((iclr) => iclr.id !== action.payload.id),
      };
    },
    addActiveClassSessionOoclrs: (active, action: PayloadAction<Array<ClassSessionOoclrApi>>) => {
      return {
        ...active,
        classSessionOoclrs: [
          ...active.classSessionOoclrs,
          ...action.payload,
        ],
      };
    },
    removeActiveClassSessionOoclrs: (active, action: PayloadAction<{ ids: Array<number> }>) => {
      return {
        ...active,
        classSessionOoclrs: active.classSessionOoclrs.filter((csIclr) => !action.payload.ids.includes(csIclr.id)),
      };
    },
    addActiveAssessmentQuestionMap(active, action: PayloadAction<AssessmentQuestionApi>) {
      // force consistent sorting of AQMs. After some research, I'm comfortable that is is OK to put this logic in a reducer
      const sortedPayload = [...active.assessmentQuestionMaps, action.payload].sort((a, b) => {
        if (a.assessmentId < b.assessmentId) {
          return -1;
        }
        if (!a.order || !b.order) {
          return 0;
        }
        if (a.assessmentId === b.assessmentId && (a.order < b.order || b.order === undefined)) {
          return -1;
        }
        return 1;
      });
      return {
        ...active,
        assessmentQuestionMaps: sortedPayload,
      };
    },
    addActiveAssessmentQuestionMaps(active, action: PayloadAction<Array<AssessmentQuestionApi>>) {
      return {
        ...active,
        assessmentQuestionMaps: [
          ...active.assessmentQuestionMaps,
          ...action.payload,
        ],
      };
    },
    setActiveAssessmentQuestionMaps(active, action: PayloadAction<Array<AssessmentQuestionApi>>) {
      return {
        ...active,
        assessmentQuestionMaps: action.payload,
      };
    },
    editActiveAssessmentQuestionMap(active, action: PayloadAction<{ id: number; delta: Partial<AssessmentQuestionApi>}>) {
      return {
        ...active,
        assessmentQuestionMaps: active.assessmentQuestionMaps.map((aqm) => {
          return aqm.id === action.payload.id
            ? { ...aqm, ...action.payload.delta }
            : aqm;
        }),
      };
    },
    removeActiveAssessmentQuestionMap(active, action: PayloadAction<Pick<AssessmentQuestionApi, 'id'>>) {
      return {
        ...active,
        assessmentQuestionMaps: active.assessmentQuestionMaps.filter((aqm) => aqm.id !== action.payload.id),
      };
    },
    removeActiveAssessmentQuestionMaps(active, action: PayloadAction<{ aqmIds: Array<number> }>) {
      const filteredAqms = active.assessmentQuestionMaps.filter((aqm) => !action.payload.aqmIds.includes(aqm.id));
      return {
        ...active,
        assessmentQuestionMaps: filteredAqms,
      };
    },
    clear: () => {
      return defaultActiveState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addClassSessionTopic.fulfilled, (active, action) => {
      return {
        ...active,
        classSessionTopics: [
          ...active.classSessionTopics,
          action.payload,
        ],
      };
    });
    builder.addCase(removeClassSessionTopic.fulfilled, (active, action) => {
      return {
        ...active,
        classSessionTopics: active.classSessionTopics.filter((cst) => cst.id !== action.payload.id),
      };
    });
    builder.addCase(reorderClassSessionTopic.fulfilled, (active, action) => {
      return {
        ...active,
        classSessionTopics: action.payload,
      };
    });
    builder.addCase(addUserTopic.fulfilled, (active, action) => {
      return {
        ...active,
        topics: [
          ...active.topics,
          action.payload,
        ],
      };
    });
  },
});

export default activeSlice;
