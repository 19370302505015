import { DateTime } from 'luxon';

import { isInThePast } from 'utils/dateFormattingFunctions';
import { CheckpointColumn, GenericObject, YesNo } from 'types/backend/shared.types';
import {
  ClarityHash,
  CorrectHash,
  PointsHash,
  RecapHash,
  SimpleEnrichedAQM,
} from 'types/common.types';
import { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import {
  EnrichedStudentStudyPath,
  EnrichedStudentTopicCard,
  EnrichedStudentTopicCardCheckpoints,
  EnrichedStudentTopicCardCheckpointStateEnum,
  EnrichedStudentTopicCardLearningObjective,
  StudentAssessmentStartedStatus,
  StudentPTState,
  StudyPathSummaryInfo,
} from './StudyPathController.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { L8yBasicItem } from 'types/backend/questions.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import {
  StudentTopicCardCheckpoints,
  StudentTopicCardLearningObjective,
  StudentTopicCardLearningObjectiveAssessmentQuestion,
  StudentTopicCardReadinessAssessment,
} from 'types/backend/studentStudyPaths.types';
import { VatHashes } from 'utils/getVatHashesFromSaqa';
import { L8yAssessmentQuestion } from 'shared-components/LearnosityContainer/LearnosityContainer';

export type SpatHashes = Omit<VatHashes, 'attemptsHash' | 'vatFrozenHash'>;

export function getStudyPathHashesFromSaqa(questions: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>) {
  let clarityHash: ClarityHash = {};
  let correctHash: CorrectHash = {};
  const pointsHash: PointsHash = {};
  const recapHash: RecapHash = {};
  questions.forEach(question => {
    if (question.studentAssessmentQuestion && question.studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt) {
      const questionClarity = question.studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt.clarity;
      if (questionClarity) {
        clarityHash = {
          ...clarityHash,
          [question.question.l8yId]: questionClarity,
        };
      }
      // Add correctness to SP hashes so action bar knows when all questions are actually correct
      const questionCorrectness = question.studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt.isCorrect;
      if (questionCorrectness) {
        correctHash = {
          ...correctHash,
          [question.question.l8yId]: questionCorrectness,
        };
      }
    }
    pointsHash[question.question.l8yId] = question.studentAssessmentQuestion?.gradedStudentAssessmentQuestionAttempt?.adjustedPointsEarned || 0;
    recapHash[question.question.l8yId] = question.studentAssessmentQuestion?.pointsAvailableToRecap ? question.studentAssessmentQuestion?.pointsAvailableToRecap > 0 : false;
  });
  return {
    clarityHash,
    correctHash,
    pointsHash,
    recapHash,
  } as SpatHashes;
}

function sortFilteredQuestions(unsortedQuestions: Array<StudyPathEnrichedQuestion>) {
  return unsortedQuestions.sort((a, b) => {
    // 1. Sort by LO Number
    // Hack: using string LO number to sort now
    // this ultimately need to be changed to grabbing the loId and then using courseWideSort from the correct LO selector
    if (a.loNumber < b.loNumber) {
      return -1;
    } else if (b.loNumber < a.loNumber) {
      return 1;
    }

    //2. Sort by AssessType
    const assessmentA = a.assessment;
    const assessmentB = b.assessment;
    const conditions = [
      assessmentA.assessType === AssessTypeEnum.Preclass,
      assessmentA.assessType === AssessTypeEnum.Homework && assessmentB.assessType !== AssessTypeEnum.Preclass,
      assessmentA.assessType === AssessTypeEnum.Prep && [AssessTypeEnum.Prep, AssessTypeEnum.PracticeTest, AssessTypeEnum.Summative].includes(assessmentB.assessType),
      assessmentA.assessType === AssessTypeEnum.PracticeTest && [AssessTypeEnum.PracticeTest, AssessTypeEnum.Summative].includes(assessmentB.assessType),
    ];
    // conditions is now an array of booleans and we can check if any of them are true with Array.some
    if (conditions.some((val) => val !== true)) {
      return 1;
    }

    // 3. sort by due date
    const aDue = DateTime.fromISO(assessmentA.mergedDueDate);
    const bDue = DateTime.fromISO(assessmentB.mergedDueDate);
    if (aDue < bDue) {
      return -1;
    }
    if (bDue < aDue) {
      return 1;
    }

    // 4. sort by question order
    if (a.order > b.order) {
      return 1;
    }
    return -1;
  });
}

export const canShowAssessmentQuestionsInStudyPath = (question: StudentTopicCardLearningObjectiveAssessmentQuestion, studentAssessment: StudentAssessmentApi | undefined, nowDate: DateTime) => {
  const isAfterDue = DateTime.fromISO(question.assessment.mergedDueDate) < nowDate;
  const allQuestionsAnswered = studentAssessment?.unansweredQuestionCount === 0;
  return allQuestionsAnswered || isAfterDue;
};

const getQuestionNumber = (assessmentId: string, questionId: number, aqms: Array<SimpleEnrichedAQM>) => {
  const foundAqm = aqms.find(aqm => aqm.questionId === questionId && aqm.assessmentId === assessmentId);
  const questionNum = foundAqm ? foundAqm.questionNumber : 0; // this covers AQMs and SSP being out of sync, possible for a brief moment if client state is out of sync
  return questionNum;
};

export interface StudyPathEnrichedQuestion extends StudentTopicCardLearningObjectiveAssessmentQuestion {
  assessmentNumber: number
  studentAssessment?: StudentAssessmentApi
  isStudyPathEligibleAssessment: boolean
  questionNumber: number
  loNumber: string
  loNumberStrings: Array<string>
}

export const enrichStudentTCLOAssessmentQuestions = (
  stcloaqs: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>,
  learningObjectives: Array<EnrichedStudentTopicCardLearningObjective>,
  studentAssessments: Array<StudentAssessmentApi>,
  simpleAqms: Array<SimpleEnrichedAQM>
): Array<StudyPathEnrichedQuestion> => {
  return stcloaqs.map(stcloaq => {
    const now = DateTime.local();
    const studentAssessment = studentAssessments.find(sa => sa.id === stcloaq.studentAssessmentQuestion?.studentAssessmentId);
    const isStudyPathEligibleAssessment = canShowAssessmentQuestionsInStudyPath(stcloaq, studentAssessment, now);
    const { assessmentNumber } = simpleAqms.find((aqm) => aqm.assessmentId === stcloaq.assessment.id) as SimpleEnrichedAQM;
    const questionNumber = getQuestionNumber(stcloaq.assessment.id, stcloaq.question.id, simpleAqms);
    const { questionLearningObjectives } = stcloaq;
    const loNumberStrings = questionLearningObjectives.reduce((acc: Array<string>, loId) => {
      // sometimes all of the learningObjectives from the qLos are not passed in the array, so this find will sometimes return undef but it still works
      const { loNumber } = learningObjectives.find((lo) => lo.id === loId) || {};
      if (loNumber) {
        acc.push(loNumber);
      }
      return acc;
    }, []);
    // TODO: phase out `loNumber` in favor of `loNumberStrings` but for now maintain backwards-compatibility
    const [loNumber] = loNumberStrings;
    return {
      ...stcloaq,
      assessmentNumber,
      studentAssessment,
      isStudyPathEligibleAssessment,
      questionNumber,
      loNumber,
      loNumberStrings,
    };
  });
};

export function filterAndEnrichTodoPastQuestions(
  questions: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>,
  checkpointType: CheckpointColumn,
  learningObjectives: Array<EnrichedStudentTopicCardLearningObjective>,
  studentAssessments: Array<StudentAssessmentApi>,
  simpleAqms: Array<SimpleEnrichedAQM>
) {
  // Divvy up arrays for display in nav groups
  const incorrectArray: Array<StudyPathEnrichedQuestion> = [];
  const correctArray: Array<StudyPathEnrichedQuestion> = [];
  const unattemptedQuestions: Array<StudyPathEnrichedQuestion> = [];
  const excludedQuestions: Array<StudyPathEnrichedQuestion> = [];
  let todoQuestions: Array<StudyPathEnrichedQuestion> = [];
  let pastQuestions: Array<StudyPathEnrichedQuestion> = [];

  // StudentTopicCardLearningObjectiveAssessmentQuestion --> StudyPathEnrichedQuestion
  const enrichedSTCLOAQuestions: Array<StudyPathEnrichedQuestion> = enrichStudentTCLOAssessmentQuestions(questions, learningObjectives, studentAssessments, simpleAqms);

  switch (checkpointType) {
    case CheckpointColumn.Test:
      enrichedSTCLOAQuestions.forEach((question) => {
        const { assessment: { assessType }, isStudyPathEligibleAssessment, studentAssessmentQuestion } = question;
        if (assessType === AssessTypeEnum.PracticeTest && isStudyPathEligibleAssessment && !!studentAssessmentQuestion) {
          if (studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes) {
            pastQuestions.push(question);
          } else if (studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.No) {
            todoQuestions.push(question);
          } else if (studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt === null) {
            todoQuestions.push(question);
          }
        } else if ((assessType === AssessTypeEnum.Prep) ||
          ([AssessTypeEnum.Homework, AssessTypeEnum.Preclass].includes(assessType) && isStudyPathEligibleAssessment)) {
          pastQuestions.push(question);
        } else {
          excludedQuestions.push(question);
        }
      });
      break;

    case CheckpointColumn.Review:
      enrichedSTCLOAQuestions.forEach((question) => {
        if ([AssessTypeEnum.Homework, AssessTypeEnum.Preclass].includes(question.assessment.assessType) && question.isStudyPathEligibleAssessment) {
          if (question.studentAssessmentQuestion && question.studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt) {
            if (question.studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt.isCorrect === YesNo.No) {
              incorrectArray.push(question);
            } else {
              correctArray.push(question);
            }
          } else {
            unattemptedQuestions.push(question);
          }
        } else {
          excludedQuestions.push(question);
        }
      });
      todoQuestions = [...incorrectArray, ...unattemptedQuestions];
      pastQuestions = [...correctArray];
      break;

    case CheckpointColumn.Prep:
      enrichedSTCLOAQuestions.forEach((question) => {
        if (question.assessment.assessType === AssessTypeEnum.Prep) {
          if (question.studentAssessmentQuestion?.latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.No) {
            incorrectArray.push(question);
          } else if (question.studentAssessmentQuestion?.latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes) {
            correctArray.push(question);
          } else {
            unattemptedQuestions.push(question);
          }
        } else if ([AssessTypeEnum.Homework, AssessTypeEnum.Preclass].includes(question.assessment.assessType) && question.isStudyPathEligibleAssessment) {
          correctArray.push(question);
        } else {
          excludedQuestions.push(question);
        }
      });

      todoQuestions = [...incorrectArray, ...unattemptedQuestions];
      pastQuestions = [...correctArray];
      break;

    default:
      todoQuestions = [];
      pastQuestions = [];
      break;
  }
  todoQuestions = sortFilteredQuestions(todoQuestions);
  pastQuestions = sortFilteredQuestions(pastQuestions);
  return {
    todoQuestions,
    pastQuestions,
    excludedQuestions,
    correctArray,
    incorrectArray,
  };
}

// this allows for passing in an empty object and returning defaults in situations where studypath summaryInfo doesn't exist
export function calculateStudyPathPercentages({
  reviewedTopics = 0,
  totalTopics = 0,
  testMeTopics = 0,
  totalPrepQuestions = 0,
  correctPrepQuestions = 0,
}: StudyPathSummaryInfo) {
  // calculate percentages, don't divide by zero
  const topicsReviewedPercentage = totalTopics > 0
    ? Math.round(reviewedTopics / totalTopics * 100)
    : 0;
  const prepQuestionsReviewedPercentage = totalPrepQuestions > 0
    ? Math.round(correctPrepQuestions / totalPrepQuestions * 100)
    : 0;
  // get fractions as strings
  const topicsReviewedFraction = `${testMeTopics}/${totalTopics}`;
  return {
    topicsReviewedPercentage,
    topicsReviewedFraction,
    prepQuestionsReviewedPercentage,
  };
}
/***
 * This function takes the enriched SP data and returns the PT unlocked percentage
 * for all eligible (non-PT) questions depending on the status of the questions and which column they're in
 * it's possible that there's a better way to do this but at this point I'm wary of touching the retrieveEnrichedActiveStudyPath loop
*/
export const getPracticeTestUnlockedPercentage = (studentTopicCardCheckpoints: EnrichedStudentTopicCardCheckpoints | StudentTopicCardCheckpoints, studentAssessments: Array<Pick<EnrichedStudentAssessment, 'allQuestionsAnswered' | 'id'>>): number => {
  const now = DateTime.local();

  const allEligibleQuestions = ([] as Array<number | null>).concat(...Object.values(studentTopicCardCheckpoints).map((checkPointData) => {
    return ([] as Array<number | null>).concat(...checkPointData.map(({ checkpoint, learningObjectives }: {checkpoint: CheckpointColumn; learningObjectives: Array<StudentTopicCardLearningObjective>}) => {
      return ([] as Array<number | null>).concat(...learningObjectives.map(({ assessmentQuestions }: { assessmentQuestions: Array<StudentTopicCardLearningObjectiveAssessmentQuestion> }) => {
        // Don't include Practice Test questions in the count
        const assessmentQuestionsNonPracticeTest = assessmentQuestions.filter(({ assessment }) => assessment.assessType !== AssessTypeEnum.PracticeTest);
        return assessmentQuestionsNonPracticeTest.map(aq => {
          if (aq.studentAssessmentQuestion && checkpoint === CheckpointColumn.Test) {
            if ([AssessTypeEnum.Preclass, AssessTypeEnum.Homework].includes(aq.assessment.assessType)) {
              // for pre and HW we can show it if it's after the due date or all questions are answered
              const isAfterDue = DateTime.fromISO(aq.assessment.mergedDueDate) < now;
              if (isAfterDue) {
                return aq.studentAssessmentQuestion.id;
              }
              const studentAssessment = studentAssessments.find(({ id }) => id === aq.assessment.id);
              if (studentAssessment && studentAssessment.allQuestionsAnswered) {
                return aq.studentAssessmentQuestion.id;
              }
              return null;
            }
            // otherwise (prep) we can count it
            return aq.studentAssessmentQuestion.id;
          }
          return null;
        });
      }));
    }));
  }));

  // get non-null count
  const answeredQuestionCount = allEligibleQuestions.filter((val) => val).length;
  const totalQuestionCount = allEligibleQuestions.length;
  const practiceTestUnlockedPercentage = totalQuestionCount !== 0 ? Math.round((answeredQuestionCount / totalQuestionCount) * 100) : 0;
  return practiceTestUnlockedPercentage;
};

// this functions looks at the presence of topicCards in this and other columns and determines the status of the current columns based on that.
// for prep and review columns only
export function determinePrepOrReviewCheckpointState(
  checkpoint: CheckpointColumn.Prep | CheckpointColumn.Review,
  cardCheckpoints: {
    hidden: Array<GenericObject>
    prep: Array<GenericObject>
    review: Array<GenericObject>
    test: Array<GenericObject>
  }
) {
  const { hidden = [], prep = [], review = [], test = [] } = cardCheckpoints;

  const calculateBasedOnOtherColumns = (priorColumnsHaveCards: boolean, subsequentColumnsHaveCards: boolean) => {
    if (priorColumnsHaveCards && subsequentColumnsHaveCards) {
      return EnrichedStudentTopicCardCheckpointStateEnum.ActiveWithoutQuestions;
    } else if (priorColumnsHaveCards) {
      return EnrichedStudentTopicCardCheckpointStateEnum.NotStarted;
    }
    return EnrichedStudentTopicCardCheckpointStateEnum.Complete;
  };

  switch (checkpoint) {
    case CheckpointColumn.Review: {
      if (!!review.length) {
        return EnrichedStudentTopicCardCheckpointStateEnum.ActiveWithQuestions;
      }
      return calculateBasedOnOtherColumns(!!hidden.length, !!prep.length || !!test.length);
    }
    case CheckpointColumn.Prep: {
      if (prep.length) {
        return EnrichedStudentTopicCardCheckpointStateEnum.ActiveWithQuestions;
      }
      return calculateBasedOnOtherColumns(!!hidden.length || !!review.length, !!test.length);
    }
  }
}

export const getAllAssessmentQuestionsFromEnrichedSTCLOs = (enrichedStclos: Array<EnrichedStudentTopicCardLearningObjective>) => {
  // Get all questions across all LOs
  const flatQuestions: Array<StudentTopicCardLearningObjectiveAssessmentQuestion> = enrichedStclos.map(({ assessmentQuestions }) => assessmentQuestions).flat();

  return flatQuestions.reduce((acc: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>, loq) => {
    const existingQuestionIndex = acc.findIndex((combinedQ) => combinedQ.id === loq.id);
    if (existingQuestionIndex > -1) {
      const existingQ = acc[existingQuestionIndex];
      const { questionLearningObjectives } = existingQ;
      acc[existingQuestionIndex] = {
        ...existingQ,
        questionLearningObjectives: [...new Set([...questionLearningObjectives, ...loq.questionLearningObjectives])],
      };
      return acc;
    }
    return [
      ...acc,
      loq,
    ];
  }, []);
};

export interface QuestionDatumForSPATL8y extends L8yAssessmentQuestion {
  id: number
  assessmentQuestionId: number
  assessmentQuestionPoints: number
  studentAssessmentId: number
}

export const enrichQuestionDataForSPATL8y = (
  l8yArray: Array<L8yBasicItem>,
  stcloaqs: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>
): Array<QuestionDatumForSPATL8y> => {
  return l8yArray.reduce((acc: Array<QuestionDatumForSPATL8y>, { l8yId, type, gradingType }) => {
    const fullSTCLOAQ = stcloaqs.find((q: StudentTopicCardLearningObjectiveAssessmentQuestion) => q.question.l8yId === l8yId);
    if (!fullSTCLOAQ) {
      return acc;
    }
    const { question, id, studentAssessmentQuestion, points, assessment } = fullSTCLOAQ;
    return [
      ...acc,
      {
        id: question.id,
        l8yId,
        assessmentQuestionId: id,
        assessmentQuestionPoints: points,
        studentAssessmentId: studentAssessmentQuestion?.studentAssessmentId,
        latestVatStudentAssessmentQuestionAttempt: studentAssessmentQuestion?.latestVatStudentAssessmentQuestionAttempt,
        type,
        gradingType,
        gradingPolicy: assessment.gradingPolicy,
      },
    ];
  }, []);
};

export const sprexIsAvailable = (readinessAssessment: StudentTopicCardReadinessAssessment, studentAssessments: Array<StudentAssessmentApi>) => {
  const hasStudentAssessmentId = studentAssessments.find((sa) => sa.assessmentId === readinessAssessment.id);
  return isInThePast(readinessAssessment.mergedDueDate) || hasStudentAssessmentId;
};

export const derivePracticeTestData = (studyPathData: EnrichedStudentStudyPath | null) => {
  if (!studyPathData) {
    return null;
  }
  const { studentTopicCardCheckpoints } = studyPathData;
  const allCheckpointTopicCards: Array<EnrichedStudentTopicCard> = Object.values(studentTopicCardCheckpoints).map((topicCardArray) => topicCardArray).flat();
  if (!allCheckpointTopicCards.length) {
    return null;
  }
  const allLOsFromTopicCards: Array<EnrichedStudentTopicCardLearningObjective> = allCheckpointTopicCards.map(({ learningObjectives }) => learningObjectives).flat();
  const onlyLOsWithPracticeTestQuestions = allLOsFromTopicCards.filter(({ assessmentQuestions }) => {
    return assessmentQuestions.some(({ assessment }: { assessment: { assessType: AssessTypeEnum } }) => assessment.assessType === AssessTypeEnum.PracticeTest);
  });
  const filterQuestionsWithinLOs = onlyLOsWithPracticeTestQuestions.map((loData) => {
    const { assessmentQuestions } = loData;
    const filteredAssessmentQuestions = assessmentQuestions.filter(({ assessment }: { assessment: { assessType: AssessTypeEnum } }) => assessment.assessType === AssessTypeEnum.PracticeTest);
    return {
      ...loData,
      assessmentQuestions: filteredAssessmentQuestions,
    };
  });
  const allPTAQsFromLOs = filterQuestionsWithinLOs.map(({ assessmentQuestions }) => assessmentQuestions).flat();
  // get correct and incorrect arrays allowing for absence of gradedStudentQuestionAttempt
  const [correctQuestions, incorrectQuestions] = allPTAQsFromLOs.reduce((acc: [Array<StudentTopicCardLearningObjectiveAssessmentQuestion>, Array<StudentTopicCardLearningObjectiveAssessmentQuestion>], cur, idx) => {
    const { gradedStudentAssessmentQuestionAttempt: gSAQA } = cur.studentAssessmentQuestion || {};
    // questions without graded correct attempt should be included in incorrectQuestions
    const hasGradedCorrectAttempt = !!gSAQA && gSAQA.isCorrect && gSAQA.isCorrect === YesNo.Yes;
    if (hasGradedCorrectAttempt) {
      acc[0].push(cur);
    } else {
      acc[1].push(cur);
    }
    return acc;
  }, [[], []]);
  // use l8y session id from the first topic card
  const [{ l8ySessionId }] = allCheckpointTopicCards || [{}];
  return {
    learningObjectives: filterQuestionsWithinLOs,
    correctQuestions,
    incorrectQuestions,
    l8ySessionId,
  };
};

export const getStudentPracticeTestState = ({
  allAssessmentsCompleted,
  allTopicsInCheckpoint3,
  allVisibleTopicsInCheckpoint3,
  isBeforeDue,
  isCloseToPracticeTestDue,
  published,
  studentAssessmentStatus,
  totalQuestions,
}: {
  allAssessmentsCompleted: boolean
  allTopicsInCheckpoint3: boolean
  allVisibleTopicsInCheckpoint3: boolean
  isBeforeDue: boolean
  isCloseToPracticeTestDue: boolean
  published: YesNo
  studentAssessmentStatus: StudentAssessmentStartedStatus
  totalQuestions: number
}): StudentPTState => {
  let ptState: StudentPTState;
  if (published === YesNo.No && isBeforeDue && allTopicsInCheckpoint3 && allAssessmentsCompleted) {
    ptState = StudentPTState.PTReadyUnpublished;
  } else if (published === YesNo.No && !isBeforeDue) {
    ptState = StudentPTState.PTPastDueUnpublished;
  } else if (isBeforeDue && studentAssessmentStatus === StudentAssessmentStartedStatus.InProgress) {
    // published must be Yes
    ptState = StudentPTState.PTInProgress;
  } else if (!!totalQuestions && published === YesNo.Yes && (!isBeforeDue || studentAssessmentStatus === StudentAssessmentStartedStatus.Completed)) {
    ptState = StudentPTState.PTCompletedOrPastDue;
  } else if (!totalQuestions && published === YesNo.Yes && !isBeforeDue) {
    // edge case: no Qs on PT and past due
    ptState = StudentPTState.PTCompletedOrPastDue;
  } else if (published === YesNo.Yes && studentAssessmentStatus === StudentAssessmentStartedStatus.NotStarted && isBeforeDue && (
    (allTopicsInCheckpoint3 && allAssessmentsCompleted) || isCloseToPracticeTestDue
  )) {
    ptState = StudentPTState.PTAvailable;
  } else if (!totalQuestions && published === YesNo.Yes && isBeforeDue && (
    (allTopicsInCheckpoint3 && allAssessmentsCompleted) || isCloseToPracticeTestDue
  )) {
    // edge case: no Qs on PT but otherwise everything is ready to go
    ptState = StudentPTState.PTAvailable;
  } else if (isBeforeDue && allVisibleTopicsInCheckpoint3 && !allAssessmentsCompleted) {
    ptState = StudentPTState.AssignmentsNotCompleted;
  } else if (isBeforeDue) {
    ptState = StudentPTState.CardsNotMoved;
  } else {
    console.error('getStudentPracticeTestState: Default Student PT State');
    ptState = StudentPTState.CardsNotMoved;
  }
  console.debug(`getStudentPracticeTestState: Student PT State: ${ptState}; allAssessmentsCompleted: ${allAssessmentsCompleted}, allTopicsInCheckpoint3: ${allTopicsInCheckpoint3}, isBeforeDue: ${isBeforeDue}, isCloseToPracticeTestDue: ${isCloseToPracticeTestDue}, published: ${published}, studentAssessmentStatus: ${studentAssessmentStatus}`);
  return ptState;
};
