import { DateTime } from 'luxon';
import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import apiNext, { useGetProductQuery, SimplifiedErrorResponse } from 'api-next';
import useEffectOnce from 'hooks/useEffectOnce';
import useLocalStorage from 'hooks/useLocalStorage';
import ApiErrorDisplay, { ApiError } from 'shared-components/ApiErrorDisplay/ApiErrorDisplay';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import Spinner from 'shared-components/Spinner/Spinner';
import ClassDayPicker from 'instructor/controllers/Course/CourseDetailsController/components/ClassDayPicker';
import ProductsDropdown from 'instructor/components/Dropdown/ProductsDropdown';
import { ContextMethod } from 'types/backend/shared.types';
import { CourseApi } from 'types/backend/courses.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { InstitutionApi } from 'types/backend/institutions.types';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApiIdNameEmail } from 'types/backend/users.types';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { WorkStatus } from 'types/backend/workerPipelines.types';
import './DuplicateCourses.scss';


export default function DuplicateCourses() {
  const [subjects, setSubjects] = useState<Array<SubjectApi>>([] as Array<SubjectApi>);

  const [courseInput, setCourseInput] = useState('');
  const [originalCourse, setOriginalCourse] = useState<CourseApi>();
  const [courseSearchError, setCourseSearchError] = useState<ApiError>();
  const [originalCourseDaysArray, setOriginalCourseDaysArray] = useState(originalCourse?.courseDays || []);
  const [originalCourseCoinstructorUsers, setOriginalCourseCoinstructorUsers] = useState('');

  const [institutions, setInstitutions] = useState([] as Array<DropdownOption<number>>);
  const [institutionId, setInstitutionId] = useState(originalCourse?.institutionId || null);

  const [productId, setProductId] = useState(originalCourse?.productId as number);

  const originalCourseStartDate = originalCourse ? DateTime.fromISO(originalCourse.startDate).toJSDate() : null;
  const originalCourseEndDate = originalCourse ? DateTime.fromISO(originalCourse.endDate).toJSDate() : null;
  const originalCourseAccessDate = originalCourse ? DateTime.fromISO(originalCourse.accessDate).toJSDate() : null;
  const originalCourseGraceEndDate = originalCourse ? DateTime.fromISO(originalCourse.graceEndDate).toJSDate() : null;

  const [userEmailInput, setUserEmailInput] = useState('');
  const [coinstructorUserEmailsInput, setCoinstructorUserEmailsInput] = useState('');
  const [includeCustom, setIncludeCustom] = useState(true);
  const [includeClassSessions, setIncludeClassSessions] = useState(true);
  const [nameInput, setNameInput] = useState(originalCourse?.name);
  const [courseNumberInput, setCourseNumberInput] = useState(originalCourse?.courseNumber);
  const [newCourseDaysArray, setNewCourseDaysArray] = useState(originalCourse?.courseDays || []);
  const [startDateInput, setStartDateInput] = useState(originalCourseStartDate);
  const [endDateInput, setEndDateInput] = useState(originalCourseEndDate);
  const [accessDateInput, setAccessDateInput] = useState(originalCourseAccessDate);
  const [graceEndDateInput, setGraceEndDateInput] = useState(originalCourseGraceEndDate);

  const [duplicatedCourse, setDuplicatedCourse] = useState<CourseApi>();
  const [duplicatedCourseId, setDuplicatedCourseId] = useState(duplicatedCourse ? duplicatedCourse.id : '');
  const [duplicationInProgress, setDuplicationInProgress] = useState(false);
  const [duplicationError, setDuplicationError] = useState<ApiError>();
  const [enableSubmitDuplicateCourse, setEnableSubmitDuplicateCourse] = useState(false);

  const [duplicateCourseJobId, setDuplicateCourseJobId] = useLocalStorage('DUPLICATE_COURSE_JOB_ID', '');
  const [delay, setDelay] = useLocalStorage('DUPLICATE_COURSE_DELAY', 500);
  const [shouldStop, setShouldStop] = useState(false);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  // skip this query if originalCourse is not yet defined
  const { data: originalCourseProduct } = useGetProductQuery(originalCourse?.productId || -1, { skip: !originalCourse?.productId });

  const { REACT_APP_MAX_DELAY_TIME } = process.env;

  const enableSubmitCourseSearch = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/i.test(courseInput);

  useEffect(() => {
    const result = (originalCourse && originalCourse.id && !duplicationInProgress) as boolean;
    setEnableSubmitDuplicateCourse(result);
  }, [originalCourse, duplicationInProgress]);

  useEffect(() => {
    if (!duplicateCourseJobId) {
      return;
    }

    function resetInitialState() {
      setDuplicationInProgress(false);
      setEnableSubmitDuplicateCourse(true);
      setShouldStop(true);
      setDelay(500);
      setDuplicateCourseJobId('');
    }

    intervalId.current = setInterval(async () => {
      try {
        intervalId.current && clearInterval(intervalId.current);

        if (duplicateCourseJobId) {
          intervalId.current && clearInterval(intervalId.current);
        }

        const response = await apiNext.getWorkerPipeline(duplicateCourseJobId);
        const { workStatus } = response;

        if (workStatus !== WorkStatus.InProgress) {
          resetInitialState();

          intervalId.current && clearInterval(intervalId.current);

          if (workStatus === WorkStatus.Completed) {
            const { outcome, payload } = response;

            if (!duplicatedCourse) {
              populateDuplicateCourseId(outcome as CourseApi, payload.userEmail, payload.coinstructorUserEmails);
              setDuplicatedCourse(outcome as CourseApi);
            } else {
              setDuplicatedCourseId(outcome?.id);
            }
            toast.success('Duplication completed!');
          }

          if (workStatus === WorkStatus.Failed) {
            const { error } = response;
            const { name, code, message } = error as ApiError;
            setDuplicationError({ name, code, message });
          }
          return;
        }

        const newDelay = (delay * 1.5) + 1000;
        if (newDelay >= Number(REACT_APP_MAX_DELAY_TIME)) {
          const timeoutError = new Error('Duplication process timed out, try later') as ApiError;
          timeoutError.name = 'Timeout';
          timeoutError.code = 504;
          throw timeoutError;
        }

        setDelay(newDelay);
      } catch (error) {
        const { name, code, message } = error as ApiError;
        resetInitialState();
        setDuplicationError({ name, code, message });
      }
    }, delay);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };

  }, [delay, duplicateCourseJobId, REACT_APP_MAX_DELAY_TIME, duplicatedCourse, setDelay, setDuplicateCourseJobId]);

  useEffect(() => {
    if (shouldStop) {
      intervalId.current && clearInterval(intervalId.current);
    }
  }, [shouldStop]);

  useEffectOnce(() => {
    async function getSubjects() {
      const allSubjects = await apiNext.getSubjects();
      setSubjects(allSubjects);
    }
    async function getInstitutions() {
      const allInstitutions: Array<InstitutionApi> = await apiNext.getInstitutions();
      const institutionNames = allInstitutions.map(institution => {
        return {
          value: institution.id,
          label: `${institution.name} | ${institution.location}`,
        };
      });
      setInstitutions(
        [
          // default reset value
          {
            label: 'No institution',
            value: null as unknown as number,
          },
          ...institutionNames,
        ]
      );
    }

    getSubjects();
    getInstitutions();

    if (duplicateCourseJobId) {
      triggerInProgress();
    }
  });

  function populateDuplicateCourseId(course: CourseApi, email: string, coinstructorUserEmails: string) {
    setDuplicatedCourseId(course.id);
    setUserEmailInput(email);
    setNameInput(course.name);
    setCourseNumberInput(course.courseNumber);
    setNewCourseDaysArray(course.courseDays);
    setStartDateInput(DateTime.fromISO(course.startDate).toJSDate());
    setEndDateInput(DateTime.fromISO(course.endDate).toJSDate());
    setAccessDateInput(DateTime.fromISO(course.accessDate).toJSDate());
    setGraceEndDateInput(DateTime.fromISO(course.graceEndDate).toJSDate());
    setProductId(course.productId);
    setInstitutionId(course.institutionId);
    setCoinstructorUserEmailsInput(coinstructorUserEmails);
  }

  function triggerInProgress() {
    setEnableSubmitDuplicateCourse(false);
    setDuplicationInProgress(true);
  }

  function mapSubjectIdToName(subjectId?: number): string {
    if (subjectId) {
      const subject = subjects.find(({ id }) => id === subjectId);
      return subject?.name as string;
    }
    return '';
  }

  const handleInstitutionChange = (option: DropdownOption<number> | null) => {
    if (typeof option?.value === 'number') {
      setInstitutionId(option.value);
    } else {
      setInstitutionId(null);
    }
  };

  async function doSearchForCourse(event: React.SyntheticEvent) {
    event.preventDefault();
    setDuplicatedCourseId('');
    const newOriginalCourse = await apiNext.getCourse(courseInput);
    const { error } = newOriginalCourse as SimplifiedErrorResponse;
    if (error) {
      const { name, code, message } = error;
      setCourseSearchError({ name, code, message });
    } else {
      setCourseSearchError(undefined);
      setDuplicationError(undefined);
      setOriginalCourse(newOriginalCourse as CourseApi);
      const {
        name,
        startDate,
        endDate,
        accessDate,
        graceEndDate,
        courseDays,
        courseNumber,
        institutionId: newInstitutionId,
        productId: newProductId,
        creatorUserId,
      } = newOriginalCourse as CourseApi;
      const originalStartDate = DateTime.fromISO(startDate).toJSDate();
      const originalEndDate = DateTime.fromISO(endDate).toJSDate();
      const originalAccessDate = DateTime.fromISO(accessDate).toJSDate();
      const originalGraceEndDate = DateTime.fromISO(graceEndDate).toJSDate();
      setNameInput(name);
      setCourseNumberInput(courseNumber);
      setStartDateInput(originalStartDate);
      setEndDateInput(originalEndDate);
      setAccessDateInput(originalAccessDate);
      setGraceEndDateInput(originalGraceEndDate);
      setOriginalCourseDaysArray(courseDays);
      setNewCourseDaysArray(courseDays);
      setInstitutionId(newInstitutionId);
      setProductId(newProductId);
      const originalCourseInstructorUsers = await apiNext.getUsersByCourseAndRole((newOriginalCourse as CourseApi).id, RoleEnum.Instructor);
      const originalCoinstructors = originalCourseInstructorUsers.reduce((acc: Array<string>, cur) => {
        if (cur.id !== creatorUserId) {
          acc.push(cur.email);
        }
        return acc;
      }, []).join(', ');
      setOriginalCourseCoinstructorUsers(originalCoinstructors);
      setCoinstructorUserEmailsInput(originalCoinstructors);
      setUserEmailInput((originalCourseInstructorUsers.find(({ id }) => id === creatorUserId) as UserApiIdNameEmail).email);
    }
  }

  async function doDuplicateCourse(event: React.SyntheticEvent) {
    event.preventDefault();
    setDuplicatedCourseId('');
    triggerInProgress();
    setDuplicationError(undefined);

    const result = await apiNext.postWorkerPipeline('duplicate-courses', ContextMethod.Create, {
      courseId: originalCourse?.id as string,
      userEmail: userEmailInput.toLowerCase().trim(),
      startDate: DateTime.fromJSDate(startDateInput as Date).toISODate(),
      endDate: DateTime.fromJSDate(endDateInput as Date).toISODate(),
      accessDate: DateTime.fromJSDate(accessDateInput as Date).toISODate(),
      graceEndDate: DateTime.fromJSDate(graceEndDateInput as Date).toISODate(),
      courseDays: newCourseDaysArray,
      courseNumber: courseNumberInput,
      name: nameInput,
      institutionId: !!institutionId ? institutionId : null,
      productId,
      includeCustom,
      coinstructorUserEmails: coinstructorUserEmailsInput,
      includeClassSessions,
    });

    const { error } = result;
    if (error) {
      const { name, code, message } = error;
      setDuplicationError({ name, code, message });
      setDuplicationInProgress(false);
    } else {
      const { id } = result;
      setDuplicateCourseJobId(id);
    }
  }

  return (
    <div className="duplicate-courses">
      <div className="duplicate-courses-search">
        <form className="duplicate-courses-search__form" onSubmit={doSearchForCourse}>
          <label htmlFor="original-course-id-search">Search for course by id</label>
          <input
            id="original-course-id-search"
            type="text"
            value={courseInput}
            placeholder="courseId"
            disabled={duplicationInProgress}
            onChange={e => setCourseInput(e.target.value)}
          />
          <BetterButton
            disabled={!enableSubmitCourseSearch || duplicationInProgress}
            primary
            text="Search"
            onClick={doSearchForCourse}
          />
        </form>
        {duplicationInProgress && (
          <div className="duplicate-courses-search__loading">
            <Spinner size="3em" />
            <span>
              Duplication in progress...
            </span>
          </div>
        )}
        {!!courseSearchError && (
          <ApiErrorDisplay
            error={courseSearchError}
          />
        )}
      </div>
      <div className="duplicate-courses-courses row">
        <div className="duplicate-courses-courses__original-course col-xs-12 col-md-6">
          <h2>Original Course</h2>
          <label htmlFor="original-course-id">id</label>
          <input
            id="original-course-id"
            type="text"
            value={originalCourse?.id || ''}
            disabled
          />
          <label htmlFor="original-course-name">name</label>
          <input
            id="original-course-name"
            type="text"
            value={originalCourse?.name || ''}
            disabled
          />
          <label htmlFor="original-course-number">course number</label>
          <input
            id="original-course-number"
            type="text"
            value={originalCourse?.courseNumber || ''}
            disabled
          />
          <label htmlFor="original-course-creator">user id</label>
          <input
            id="original-course-creator"
            type="text"
            value={originalCourse?.creatorUserId || ''}
            disabled
          />
          <label htmlFor="original-course-coinstructors">coinstructors</label>
          <textarea
            id="original-course-coinstructors"
            value={originalCourseCoinstructorUsers}
            disabled
          />
          <label htmlFor="original-course-days">course days</label>
          <ClassDayPicker
            disabled
            days={originalCourseDaysArray}
            updateDays={setOriginalCourseDaysArray}
          />
          <label htmlFor="original-course-start">start date</label>
          <DatePicker
            disabled
            name="original-course-start"
            onChange={() => {}}
            selected={originalCourseStartDate}
          />
          <label htmlFor="original-course-access">access date</label>
          <DatePicker
            disabled
            name="original-course-access"
            onChange={() => {}}
            selected={originalCourseAccessDate}
          />
          <label htmlFor="original-course-grace-end">last grace period day</label>
          <DatePicker
            disabled
            name="original-course-grace-end"
            onChange={() => {}}
            selected={originalCourseGraceEndDate}
          />
          <label htmlFor="original-course-end">end date</label>
          <DatePicker
            disabled
            name="original-course-end"
            onChange={() => {}}
            selected={originalCourseEndDate}
          />
          <label htmlFor="original-course-subject">subject</label>
          <input
            id="original-course-subject"
            type="text"
            value={mapSubjectIdToName(originalCourse?.subjectId)}
            disabled
          />
          <label htmlFor="original-course-additional-subjects">additional subjects</label>
          <input
            id="original-course-additional-subject"
            type="text"
            value={originalCourse?.additionalSubjectIds.map(s => mapSubjectIdToName(s)).join(', ')}
            disabled
          />
          <label htmlFor="original-course-parent">parent course id</label>
          <input
            id="original-course-parent"
            type="text"
            value={originalCourse?.parentCourseId || ''}
            disabled
          />
          <label htmlFor="original-course-product">product</label>
          <input
            id="original-course-product"
            type="text"
            value={originalCourseProduct?.title || ''}
            disabled
          />
          <label htmlFor="original-course-institution">institution</label>
          <input
            id="original-course-institution"
            type="text"
            value={originalCourse?.institutionId ? institutions.find(item => item.value === originalCourse.institutionId)?.label : ''}
            disabled
          />
        </div>
        <div className="duplicate-courses-courses__duplicate-course col-xs-12 col-md-6">
          <h2>New Course</h2>
          <form className="duplicate-courses-courses__duplicate-form" onSubmit={doDuplicateCourse}>
            <label htmlFor="new-course-id">new course id</label>
            <div className="duplicate-courses-courses__new-course-id">
              <input
                id="new-course-id"
                type="text"
                value={duplicatedCourseId}
                placeholder="will be filled in after duplicate course is created"
                disabled
              />
            </div>
            <label htmlFor="new-course-name">new name</label>
            <input
              id="new-course-name"
              type="text"
              value={nameInput}
              onChange={e => setNameInput(e.target.value)}
              disabled={!enableSubmitDuplicateCourse}
            />
            <label htmlFor="new-course-number">new course number</label>
            <input
              id="new-course-number"
              type="text"
              value={courseNumberInput}
              onChange={e => setCourseNumberInput(e.target.value)}
              disabled={!enableSubmitDuplicateCourse}
            />
            <label htmlFor="new-course-user-email">new course user email</label>
            <input
              id="new-course-user-email"
              type="text"
              value={userEmailInput}
              placeholder="user email (leave blank to keep same user)"
              onChange={e => setUserEmailInput(e.target.value)}
              disabled={!enableSubmitDuplicateCourse}
            />
            <label htmlFor="new-course-coinstructor-user-emails">new course coinstructor emails</label>
            <textarea
              id="new-course-coinstructor-user-emails"
              placeholder="Enter a comma-separated list of user emails"
              value={coinstructorUserEmailsInput}
              onChange={e => setCoinstructorUserEmailsInput(e.target.value)}
              disabled={!enableSubmitDuplicateCourse}
            />
            <div className="duplicate-courses-courses__new-duplicate-custom">
              <label htmlFor="new-course-duplicate-custom">duplicate custom content? (if changing user)</label>
              <input
                id="new-course-duplicate-custom"
                type="checkbox"
                checked={includeCustom}
                onChange={() => setIncludeCustom(!includeCustom)}
                disabled={!userEmailInput.length}
              />
            </div>
            <div className="duplicate-courses-courses__new-duplicate-class-sessions">
              <label htmlFor="new-course-duplicate-class-sessions">duplicate class labels and non-class days?</label>
              <input
                id="new-course-duplicate-class-sessions"
                type="checkbox"
                checked={includeClassSessions}
                onChange={() => setIncludeClassSessions(!includeClassSessions)}
              />
            </div>
            <label htmlFor="new-course-days">new course days</label>
            <ClassDayPicker
              disabled={!enableSubmitDuplicateCourse}
              days={newCourseDaysArray}
              updateDays={setNewCourseDaysArray}
            />
            <label htmlFor="new-course-start">new start date</label>
            <DatePicker
              disabled={!enableSubmitDuplicateCourse}
              name="new-course-start"
              onChange={val => !!val && setStartDateInput(val)}
              selected={startDateInput}
            />
            <label htmlFor="new-course-access">new access date</label>
            <DatePicker
              disabled={!enableSubmitDuplicateCourse}
              name="new-course-access"
              onChange={val => !!val && setAccessDateInput(val)}
              selected={accessDateInput}
            />
            <label htmlFor="new-course-grace-end">new last grace period day</label>
            <DatePicker
              disabled={!enableSubmitDuplicateCourse}
              name="new-course-grace-end"
              onChange={val => !!val && setGraceEndDateInput(val)}
              selected={graceEndDateInput}
            />
            <label htmlFor="new-course-end">new end date</label>
            <DatePicker
              disabled={!enableSubmitDuplicateCourse}
              name="new-course-end"
              onChange={val => !!val && setEndDateInput(val)}
              selected={endDateInput}
            />
            <label htmlFor="new-course-product">product</label>
            <div id="select-product">
              <ProductsDropdown
                selectedProductId={productId}
                onChange={setProductId}
              />
            </div>
            <label htmlFor="new-course-institution">institution</label>
            <div id="select-institution">
              <Select
                className="form-select"
                id="new-course-institution"
                isDisabled={!enableSubmitDuplicateCourse}
                options={institutions}
                value={institutions.find(v => v.value === institutionId) || null}
                name="institutionId"
                onChange={(option) => handleInstitutionChange(option)}
              />
            </div>
            <BetterButton
              className="submit-button"
              disabled={!enableSubmitDuplicateCourse}
              primary
              text={duplicationInProgress ? 'In progress' : 'Duplicate'}
              onClick={doDuplicateCourse}
            />
          </form>
          {!!duplicationError && (
            <ApiErrorDisplay
              error={duplicationError}
            />
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        newestOnTop
        hideProgressBar
        autoClose={false}
      />
    </div>
  );
}
